<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.1" width="40" height="40" rx="4" fill="#FF595E" />
    <path
      d="M11 13C11 11.8954 11.8954 11 13 11H27C28.1046 11 29 11.8954 29 13V27C29 28.1046 28.1046 29 27 29H13C11.8954 29 11 28.1046 11 27V13Z"
      fill="#FF595E"
    />
    <path
      d="M17 23L23 17"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M23 23L17 17"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
