<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="flex flex-col justify-center pt-2-5 sm:pt-5 -mb-2">
          <div class="flex flex-col">
            <lf-upload
              acceptTypes=".pdf"
              v-for="(month, index) in requiredMonthReports"
              :key="month.name"
              :fileTypeName="`bank_statements_${month.monthIndex}`"
              @uploadComplete="month.val = $event"
            >
              {{ month.name }}
              {{ index === 0 ? "Transaction report (optional)" : "Statement" }}
            </lf-upload>
          </div>

          <expand-docs-menu
            :diameter="diameter"
            :completedStepsPercentage="completedStepsPercentage"
            @toggleAdditionalDocsMenu="toggleAdditionalReports"
          />
          <div
            v-show="showAdditionalReports"
            class="flex flex-col h-56 overflow-y-scroll"
          >
            <lf-upload
              acceptTypes=".pdf"
              v-for="month in optionalMonthReports"
              :key="month.name"
              :fileTypeName="`bank_statements_${month.monthIndex}`"
              v-model="month.val"
            >
              {{ month.name }} Statement
            </lf-upload>
          </div>
          <span class="flex flex-col justify-center pt-2-5 pb-2-5 mx-2-5">
            <div class="flex justify-center w-full">
              <input-error :message="errorMessage" class="mb-4 font-semibold" />
            </div>
            <span class="flex mb-4 justify-center">
              <button
                class="px-2 w-full h-10 sm:h-13 btn btn--submit btn--plaid"
                @click="nextStep()"
              >
                {{ buttonText }}
              </button>
            </span>
            <span
              class="my-2-5 text-tiny-xs sm:text-tiny text-blue-600 font-semibold cursor-pointer justify-center flex"
              @click="verifyAccountUsingPlaid"
            >
              {{ subButtonText }}
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import Upload from "../ui/Upload";
import ExpandAdditionalDocsMenu from "../ExpandAdditionalDocsMenu";
import format from "date-fns/format";
import InputError from "@/components/ui/InputError";

export default {
  components: {
    "lf-upload": Upload,
    "expand-docs-menu": ExpandAdditionalDocsMenu,
    InputError
  },
  setup() {
    const store = useStore();

    const showAdditionalReports = ref(false);
    const currentMonth = new Date().getMonth();
    const requiredMonthReports = ref([]);
    const optionalMonthReports = ref([]);

    for (let index = 0; index < 4; index++) {
      const targetMonth = new Date();
      targetMonth.setMonth(targetMonth.getMonth() - index);
      requiredMonthReports.value.push({
        val: "",
        monthIndex: format(targetMonth, "M"),
        name: format(targetMonth, "MMMM")
      });
    }

    for (let index = 4; index < 12; index++) {
      const targetMonth = new Date();
      targetMonth.setMonth(targetMonth.getMonth() - index);
      optionalMonthReports.value.push({
        val: "",
        monthIndex: format(targetMonth, "M"),
        name: format(targetMonth, "MMMM")
      });
    }

    const files = computed(() =>
      requiredMonthReports.value.concat(optionalMonthReports.value).map(getVal)
    );

    async function nextStep() {
      if (completedStepsPercentage.value < 100) {
        errorMessage.value = "Please upload required documents";
        return;
      }
      store.commit("business/setDocuments", files.value);
      store.commit("nextScreen");
    }

    async function verifyAccountUsingPlaid() {
      store.commit("setPlaidScreen");
    }

    function toggleAdditionalReports() {
      showAdditionalReports.value = !showAdditionalReports.value;
    }

    const completedStepsPercentage = computed(() =>
      Math.round(
        (requiredMonthReports.value.filter((o, indx) => {
          //first month report no longer required
          if (o.val && indx !== 0) return o.val;
        }).length /
          3) *
          100
      )
    );

    const errorMessage = ref("");

    watch(
      () => completedStepsPercentage.value,
      (completedStepsPercentage, prevCompletedStepsPercentage) => {
        if (completedStepsPercentage !== prevCompletedStepsPercentage)
          errorMessage.value = "";
      }
    );

    return {
      buttonText: "Submit",
      subButtonText: "Or Verify bank account using Plaid",
      nextStep,
      verifyAccountUsingPlaid,
      showAdditionalReports,
      toggleAdditionalReports,
      completedStepsPercentage,
      requiredMonthReports,
      optionalMonthReports,
      currentMonth,
      diameter: computed(() => (showAdditionalReports.value ? 33 : 66)),
      errorMessage
    };
  }
};

function getVal(obj) {
  return obj.val;
}
</script>
