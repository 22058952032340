<template>
  <l-form v-slot="{ errors }" @keydown.enter.prevent @submit="nextStep">
    <div class="body-wrapper body-wrapper--step">
      <div class="section section--less-margin">
        <div class="section__title">Business details</div>
        <div class="section__subtitle">
          What business is receiving the funding?
        </div>
        <div class="section__body">
          <div class="btn-group btn-group-toggle d-flex">
            <div class="row">
              <div class="col-12 col-lg-6 mb-3">
                <Field
                  name="firstName"
                  as="lf-input"
                  rules="required|name"
                  v-model="businessInfo.firstName"
                  placeholder="First name"
                  :errorMessage="errors.firstName"
                  helpLabel="First Name"
                  type="firstName"
                  :nomargin="true"
                />
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <Field
                  name="lastName"
                  as="lf-input"
                  rules="required|name"
                  v-model="businessInfo.lastName"
                  :nomargin="true"
                  placeholder="Last name"
                  :errorMessage="errors.lastName"
                  helpLabel="Last Name"
                  type="lastName"
                />
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <div class="flex w-full">
                  <Field
                    name="countryCode"
                    as="lf-input"
                    v-mask="{ mask }"
                    rules="required|countryCode"
                    v-model="businessInfo.countryCode"
                    class="w-30 sm:w-30 my-1 sm:my-2-5 mr-0-5 sm:mr-2-5"
                    placeholder="Country Code"
                    :errorMessage="errors.countryCode"
                    helpLabel="Country Code"
                    disabled
                  />
                  <Field
                    name="phone"
                    as="lf-input"
                    rules="required|usPhone"
                    v-model="businessInfo.phone"
                    class="w-full my-1 sm:my-2-5"
                    placeholder="Phone"
                    :errorMessage="errors.phone"
                    helpLabel="Phone"
                    type="telephone"
                    v-mask="'(###) ###-####'"
                  />
                </div>
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <Field
                  name="legalName"
                  as="lf-input"
                  rules="required"
                  v-model="businessInfo.legalName"
                  :nomargin="true"
                  placeholder="Business Legal name"
                  :errorMessage="errors.legalName"
                  helpLabel="Business Legal name"
                  type="legalName"
                />
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <Field
                  name="email"
                  as="lf-input"
                  rules="required|email"
                  v-model="businessInfo.email"
                  :nomargin="true"
                  placeholder="Email"
                  :errorMessage="errors.email"
                  helpLabel="Email"
                  type="email"
                />
              </div>

              <div class="col-12 col-lg-6 mb-3">
                <Field
                  name="dba"
                  as="lf-input"
                  rules=""
                  v-model="businessInfo.dba"
                  class=""
                  placeholder="DBA or Trade name"
                  helpLabel="DBA or Trade name"
                  :nomargin="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section section--less-margin">
        <div class="section__title section__title--no-subtitle">
          What kind of business entity do you have?
        </div>
        <div class="section__body">
          <lf-button-select
            :options="stepOptionsBusinessEntity"
            :selectedOption="selectedOptionBusinessEntity"
            @selected="setBusinessEntity"
          />
        </div>
      </div>

      <div class="section section--less-margin">
        <div class="section__title section__title--no-subtitle">
          Business details
        </div>

        <div class="section__body">
          <div class="btn-group btn-group-toggle d-flex" data-toggle="buttons">
            <div class="row">
              <div class="col-12 mb-3">
                <Field
                  name="address"
                  as="auto-complete"
                  v-model="businessAddress.address"
                  placeholder="Business address"
                  :errorMessage="errors.address"
                  helpLabel="Business address"
                  type="address"
                  @update:selectSuggestion="onAddressSelect"
                  :getAsyncOptions="getSmartyStreet"
                  :mapFunction="mapStreets"
                  rules="required"
                />
              </div>

              <div class="col-12 mb-3">
                <Field
                  name="secondAddress"
                  as="lf-input"
                  v-model="businessAddress.secondAddress"
                  class="w-full h-10 sm:h-13 my-1 sm:my-2-5"
                  placeholder="Business address line 2"
                  :errorMessage="errors.secondAddress"
                  helpLabel="Business address line 2"
                  type="secondAddress"
                  :autocomplete="true"
                />
              </div>

              <div class="col-12 col-lg-3 col-md-6 mb-3">
                <Field
                  name="country"
                  as="lf-dropdown-input"
                  v-model="businessAddress.country"
                  class="w-full h-10 sm:h-13 my-1 sm:my-2-5 mr-2 sm:mr-0"
                  placeholder="Country"
                  helpLabel="Country"
                  :options="countries"
                  :errorMessage="errors.country"
                  disabled
                  :autocomplete="true"
                  rules="required|name"
                />
              </div>

              <div class="col-12 col-lg-3 col-md-6 mb-3">
                <Field
                  name="state"
                  as="lf-dropdown-input"
                  v-model="businessAddress.state"
                  class="w-full h-10 sm:h-13 my-1 sm:my-2-5 mr-2 sm:mr-0"
                  placeholder="State"
                  :errorMessage="errors.state"
                  helpLabel="State"
                  type="state"
                  :options="states"
                  :autocomplete="true"
                  rules="required|name"
                />
              </div>

              <div class="col-12 col-lg-3 col-md-6 mb-3">
                <Field
                  name="city"
                  as="lf-input"
                  v-model="businessAddress.city"
                  class="w-full h-10 sm:h-13 my-1 sm:my-2-5"
                  placeholder="City"
                  :errorMessage="errors.city"
                  helpLabel="City"
                  type="city"
                  rules="required|name"
                />
              </div>

              <div class="col-12 col-lg-3 col-md-6 mb-3">
                <Field
                  name="zip"
                  as="lf-input"
                  v-model="businessAddress.zip"
                  class="w-full h-10 sm:h-13 my-1 sm:my-2-5"
                  placeholder="ZIP"
                  :errorMessage="errors.zip"
                  helpLabel="ZIP"
                  type="zip"
                  :maxlength="10"
                  rules="required|numeric"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section section--less-margin">
        <div class="section__title section__title--no-subtitle">
          Is this a home based business?
        </div>
        <div class="section__body">
          <div class="btn-group btn-group-toggle d-flex">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <label
                  class="btn btn--choice"
                  :class="{ active: isHomeBased === true }"
                  @click="setIsHomeBased(true)"
                >
                  yes
                </label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <label
                  class="btn btn--choice"
                  :class="{ active: isHomeBased === false }"
                  @click="setIsHomeBased(false)"
                >
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section section--less-margin">
        <div class="section__title section__title--no-subtitle">
          Are you the sole owner?
        </div>
        <div class="section__body">
          <div class="btn-group btn-group-toggle d-flex">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <label
                  class="btn btn--choice"
                  :class="{ active: isSoleOwner === true }"
                  @click="setIsSoleOwner(true)"
                >
                  yes
                </label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <label
                  class="btn btn--choice"
                  :class="{ active: isSoleOwner === false }"
                  @click="setIsSoleOwner(false)"
                >
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section section--less-margin">
        <div class="section__title section__title--no-subtitle">
          What percentage of the company do you own?
        </div>
        <div class="section__body">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <Field
                as="lf-input"
                name="ownerPercentage"
                rules="required|percentage"
                v-model="ownerPercentage"
                placeholder="Company ownership"
                :errorMessage="errors.ownerPercentage"
                helpLabel="Company ownership"
                type="number"
                :max="100"
                :min="minValueOwnerPercentage"
                suffix="%"
                :nomargin="true"
                v-mask="{ mask: numMaskOwnerPercentage }"
                inputmode="numeric"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="section section--less-margin">
        <div class="section__title section__title--no-subtitle">
          What month and year did you start your business?
        </div>
        <div class="section__body">
          <div class="btn-group btn-group-toggle d-flex">
            <div class="row">
              <div class="col-12 mb-3 mb-sm-0 col-sm-6 col-md-4 col-lg-3">
                <div class="flex">
                  <div class="flex-item">
                    <lf-dropdown
                      v-model="month"
                      class="w-40 sm:w-56-5 h-11 sm:h-13 text-left"
                      :options="monthRange"
                      placeholder="Month"
                    >
                    </lf-dropdown>
                  </div>
                  <div class="flex-item">
                    <lf-dropdown
                      v-model="year"
                      class="w-40 sm:w-56-5 h-11 sm:h-13 text-left ml-2-5"
                      :options="yearRange"
                      placeholder="Year"
                    >
                    </lf-dropdown>
                  </div>
                </div>
                <div v-show="submitted" class="text-red-700 text-sm mt-2">
                  {{ errorMessageDate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 z-0">
              Use acquisition date if not founder
            </div>
          </div>
        </div>
      </div>
      <div
        class="section section--less-margin"
        v-if="Object.keys(errors).length > 0"
      >
        <span class="text-red-700 text-2xl"
          >Please complete all required fields to proceed to the next
          step.</span
        >
      </div>
    </div>
    <div class="submit">
      <button type="button" class="btn btn--confirm btn--cancel" @click="back">
        Back
      </button>
      <button class="btn btn--confirm btn--submit">
        Next
      </button>
    </div>
  </l-form>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed, watch } from "vue";
import { Form, Field } from "vee-validate";
import { smartyStreetsRequest } from "../helpers";
import { moveToSecondInput } from "../helpers.js";
import { maska } from "maska";
import Dropdown from "@/components/ui/Dropdown";
import ButtonSelect from "@/components/ui/ButtonSelect";
import _ from "lodash";

export default {
  name: "business",
  components: {
    "l-form": Form,
    Field,
    "lf-dropdown": Dropdown,
    "lf-button-select": ButtonSelect
  },
  directives: { mask: maska },
  setup() {
    window.scrollTo({ top: 0 });

    const store = useStore();

    var procore = store.getters["business/getProcore"];
    if (typeof procore.data != "undefined") {
      store.commit("business/setBusinessInfo", procore.getBusinessInfo());
      store.commit("business/setBusinessAddress", procore.getBusinessAddress());
    }

    store.dispatch("setProgress", 20);
    const countries = computed(() =>
      Object.values(
        store.getters["customizationMetadata/all"].widget_customizations
          .countries
      )
    );

    const states = computed(() =>
      Object.values(
        store.getters["customizationMetadata/all"].widget_customizations.states
      )
    );

    const stateCodes = computed(() =>
      Object.keys(
        store.getters["customizationMetadata/all"].widget_customizations.states
      )
    );
    const businessAddress = computed(
      () => store.state.business.businessAddress
    );
    businessAddress.value.country = "United States";

    const businessInfo = computed(() => store.state.business.businessInfo);
    //set country code to +1 since its only available in us.
    businessInfo.value.countryCode = "1";

    async function nextStep() {
      submitted.value = true;
      if (year.value && month.value && !inTheFuture.value) {
        store.commit("business/setBusinessStartDate", {
          year: year.value,
          month: monthNum.value,
          monthW: month.value
        });
        store.commit("business/setBusinessInfo", businessInfo.value);
        store.commit("business/setTermsOfService", true);
        store.commit("business/setBusinessAddress", businessAddress.value);
        store.commit("business/setOwnerPercentage", ownerPercentage.value);

        if (!store.getters["business/applicationId"].length) {
          try {
            await store.dispatch("business/storeBusinessInfo");
          } catch (error) {
            if (error.response.status === 403) {
              console.log(error.response.data.message);
            }
          } finally {
            await store.dispatch("business/storeBusinessAddress");
            if (isHomeBased.value) {
              store.commit(
                "business/setPersonalAddress",
                store.getters["business/businessAddress"]
              );
            }
            try {
              var procore = store.getters["business/getProcore"];
              if (typeof procore.data != undefined) {
                await store.dispatch(
                  "business/storeBusinessMetaData",
                  "Procore"
                );
              }
            } catch (error) {
              console.log(error);
            }
          }
          store.commit("nextScreen");
        } else {
          await store.dispatch("business/storeBusinessAddress");
          store.commit("nextScreen");
        }
        // store.commit("nextScreen");
      }
    }

    async function back() {
      store.commit("backScreen");
    }

    function onAddressSelect(suggestions) {
      businessAddress.value.address = suggestions.street_line;
      businessAddress.value.secondAddress = suggestions.secondary;
      businessAddress.value.country = "US";
      businessAddress.value.city = suggestions.city;
      businessAddress.value.state = suggestions.state;
      businessAddress.value.zip = suggestions.zipcode;
      moveToSecondInput();
    }

    async function getSmartyStreet() {
      const include_only_states = stateCodes.value.join(";");
      const response = await smartyStreetsRequest({
        search: businessAddress.value.address,
        include_only_states
      });
      return response.data.suggestions;
    }

    function mapStreets(suggestions) {
      return suggestions.map(x => ({
        street: x.street_line,
        city: x.city,
        state: x.state
      }));
    }

    const submitted = ref(false);
    const month = ref(store.state.business.businessStartDate.monthW);
    const year = ref(store.state.business.businessStartDate.year);
    const monthRange = [
      "1 - January",
      "2 - February",
      "3 - March",
      "4 - April",
      "5 - May",
      "6 - June",
      "7 - July",
      "8 - August",
      "9 - September",
      "10 - October",
      "11 - November",
      "12 - December"
    ];

    //change submitted to false if user picks new date
    watch([month, year], ([month, year], [prevMonth, prevYear]) => {
      if (month !== prevMonth || year !== prevYear) submitted.value = false;
    });

    const monthNum = computed(() =>
      month.value
        ?.match(/^(\d)+/)[0]
        .trim()
        .padStart(2, "0")
    );

    //is selected date in the future?
    const inTheFuture = computed(() => {
      if (!year.value && !month.value) return false;
      const now = Date.now();
      const selectedDate = new Date(year.value, monthNum.value);
      //correcting month since it starts at 0 in Date
      selectedDate.setMonth(selectedDate.getMonth() - 1);
      return selectedDate.getTime() > now;
    });

    const errorMessageDate = computed(() => {
      if (!year.value && !month.value) return "Please select a date!";
      else if (!year.value) return "Please select a year!";
      else if (!month.value) return "Please select a month!";
      else if (inTheFuture.value) return "Date cannot be in the future!";
      else return "";
    });

    function getYearStart() {
      return new Date().getFullYear();
    }

    //delivery Of Funds
    const selectedOptionBusinessEntity = computed(
      () => store.state.business.businessEntity
    );
    function setBusinessEntity(value) {
      store.commit("business/setBusinessEntity", value);
    }
    const stepOptionsBusinessEntity = computed(
      () =>
        store.getters["customizationMetadata/input"](
          store.state.options.business_entity
        ).business_entity.options
    );

    //is Home Based
    const isHomeBased = computed(() => store.state.business.isHomeBased);
    function setIsHomeBased(value) {
      store.commit("business/setHomeBased", value);
    }

    //is Sole Owner
    const isSoleOwner = computed(() => store.state.business.isSoleOwner);
    function setIsSoleOwner(value) {
      store.commit("business/setSoleOwner", value);
    }

    //owner Percentage
    const ownerPercentage = ref(store.state.business.ownerPercentage);

    return {
      nextStep,
      back,
      mask: "+#####",
      countries,
      states,
      businessAddress,
      businessInfo,
      getSmartyStreet,
      onAddressSelect,
      mapStreets,

      month,
      year,
      monthRange,
      yearRange: _.range(getYearStart(), 1899),
      submitted,
      errorMessageDate,

      selectedOptionBusinessEntity,
      setBusinessEntity,
      stepOptionsBusinessEntity,

      isHomeBased,
      setIsHomeBased,

      isSoleOwner,
      setIsSoleOwner,

      ownerPercentage,
      numMaskOwnerPercentage: "###",
      minValueOwnerPercentage: isSoleOwner.value ? 1 : 0
    };
  }
};
</script>

<style scoped></style>
