<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.1" width="40" height="40" rx="4" fill="#3769D6" />
    <path
      d="M13 13C13 11.8954 13.8954 11 15 11H25C26.1046 11 27 11.8954 27 13V27C27 28.1046 26.1046 29 25 29H15C13.8954 29 13 28.1046 13 27V13Z"
      fill="#3769D6"
    />
    <rect x="16" y="15" width="8" height="2" rx="1" fill="white" />
    <rect x="16" y="19" width="8" height="2" rx="1" fill="#9BB4EA" />
  </svg>
</template>
