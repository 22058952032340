<template>
  <div class="relative flex flex-col">
    <div class="relative h-10 sm:h-13" ref="target">
      <div @click="toggleMenu">
        <input
          v-model="inputValue"
          v-bind="$attrs"
          :disabled="disabled"
          :placeholder="placeholderText"
          :autocomplete="autocomplete ? 'on' : 'off'"
          @input="showMenu"
          @keydown.tab="handleTab"
          type="text"
          class="text-xxs sm:text-sm bg-gray-200 rounded w-full h-full placeholder-gray-600 relative cursor-pointer"
          :class="[
            helpLabel ? 'pl-2-5 sm:pl-4' : 'text-center',
            { 'pt-2-5': helpLabel && modelValue },
            { 'border border-red-600 text-xs': !!errorMessage }
          ]"
          :style="
            `color: ${customization.label_font_colour} !important; -webkit-text-fill-color: ${customization.label_font_colour};`
          "
        />

        <span
          class="ml-3 absolute inset-y-0 right-0 flex items-center mr-8 sm:mr-0 mt-2 sm:mt-4 cursor-pointer"
        >
          <svg
            class="h-5 w-5 -mr-1 sm:mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
            :style="{ color: customization.label_font_colour }"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
      </div>
      <label
        for="input"
        v-if="helpLabel && modelValue"
        class="absolute top-0 text-mob-xs sm:text-xxs leading-6 pl-2-5 sm:pl-4"
        :class="[nopadding ? 'mt-0-5' : 'mt-0-5 sm:mt-2']"
        :style="
          `color: ${customization.label_font_colour} !important; -webkit-text-fill-color: ${customization.label_font_colour};`
        "
      >
        {{ helpLabel }}
      </label>

      <div class="absolute w-full rounded-md bg-white shadow-lg z-50">
        <ul
          tabindex="-1"
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-item-3"
          id="exp_elem_list"
          class="max-h-28 sm:max-h-40 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
          :class="{ hidden: !showMenu }"
          :style="
            `color: ${customization.label_font_colour} !important; -webkit-text-fill-color: ${customization.label_font_colour};`
          "
        >
          <li
            v-for="option in filteredOptions"
            :key="option"
            class="pt-1 cursor-pointer hover:bg-gray-200 flex justify-between text-base sm:text-base font-bold"
            id="listbox-item-0"
            role="option"
            :aria-selected="modelValue == option"
            @click="selectOption(option)"
          >
            <div class="flex items-center">
              <span class="ml-3 font-normal truncate">
                {{ option }}
              </span>
            </div>
            <span
              class="inset-y-0 flex items-center pr-2"
              v-show="modelValue == option"
            >
              <svg
                class="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </li>
          <hr class="border-solid border" />
          <li
            v-for="option in remainingOptions"
            :key="option"
            class="pt-1 cursor-pointer hover:bg-gray-200 flex justify-between text-base sm:text-base"
            id="listbox-item-0"
            role="option"
            :aria-selected="modelValue == option"
            @click="selectOption(option)"
          >
            <div class="flex items-center">
              <span class="ml-3 font-normal truncate">
                {{ option }}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <input-error :message="errorMessage" />
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import InputError from "@/components/ui/InputError";

export default {
  components: {
    InputError
  },
  name: "lf-dropdown-input",
  props: {
    modelValue: [String, Number],
    options: Array,
    placeholder: String,
    closeOnOutsideClick: Boolean,
    helpLabel: {
      type: String,
      default: ""
    },
    nopadding: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const customization = {
      label_font_colour: "#fffffff"
    };
    const showMenu = ref(false);

    const inputValue = ref(props.modelValue);

    const filteredOptions = computed(() =>
      props.options.filter(option =>
        option
          .toString()
          .toLowerCase()
          .includes(inputValue.value.toLowerCase())
      )
    );

    const remainingOptions = computed(() =>
      props.options.filter(item => !filteredOptions.value.includes(item))
    );

    function openMenu() {
      showMenu.value = true;
    }

    function handleTab() {
      clearInvalidInput();
      showMenu.value = false;
    }

    const target = ref(null);

    const placeholderText = computed(
      () => props.modelValue || props.placeholder
    );

    onClickOutside(target, () => {
      showMenu.value = false;
      clearInvalidInput();
    });

    function selectOption(option) {
      inputValue.value = option;
      emit("update:modelValue", option);
      toggleMenu();
    }
    function toggleMenu() {
      if (!props.disabled) showMenu.value = !showMenu.value;
    }

    //clears input if its not one of the options
    function clearInvalidInput() {
      if (props.autocomplete) return;
      if (!props.options.includes(inputValue.value)) {
        inputValue.value = "";
        emit("update:modelValue", "");
      }
    }

    return {
      showMenu,
      selectOption,
      toggleMenu,
      target,
      inputValue,
      filteredOptions,
      placeholderText,
      clearInvalidInput,
      remainingOptions,
      handleTab,
      openMenu,
      customization
    };
  }
};
</script>
