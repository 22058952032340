import { render } from "./IconTrash.vue?vue&type=template&id=08cf068a"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "08cf068a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('08cf068a', script)) {
    api.reload('08cf068a', script)
  }
  
  module.hot.accept("./IconTrash.vue?vue&type=template&id=08cf068a", () => {
    api.rerender('08cf068a', render)
  })

}

script.__file = "src/components/icons/IconTrash.vue"

export default script