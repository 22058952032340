<template>
  <div class="px-2-5 sm:px-5">
    <div
      v-if="uploadState == STATES.INITIAL"
      class="flex justify-between w-full my-1-5 sm:my-2-5 p-2-5 sm:p-5 border-2 border-gray-300 border-dashed rounded"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="drop"
    >
      <div class="flex justify-start">
        <icon-file />
        <span
          class="pl-1-5 sm:pl-5 flex place-self-center text-tiny-xs sm:text-sm text-left text-gray-700 font-medium"
          ><slot />
        </span>
      </div>
      <div
        class="justify-end w-17 h-5 place-self-center bg-indigo-100 rounded-full font-medium text-tiny-xs sm:text-xs text-blue-700 cursor-pointer text-center"
      >
        <label class="cursor-pointer">
          Upload
          <input
            id="file-upload"
            name="file-upload"
            type="file"
            class="sr-only"
            :value="file"
            :accept="acceptTypes"
            @input="uploadFile"
          />
        </label>
      </div>
    </div>
    <div
      v-else-if="uploadState == STATES.UPLOADING"
      class="w-full flex flex-col relative z-30 justify-between pl-5 pt-5 pb-2-5 text-left border border-gray-300 rounded bg-gray-100"
    >
      <div class="bg-gray-200 absolute top-0 left-0 z-0 h-26 sm:h-32 w-5" />
      <div class="absolute top-0 h-26 sm:h-32" :style="{ width: '92%' }">
        <div
          class="bg-gray-200 absolute top-0 z-0 h-26 sm:h-32"
          :style="{ width: percentCompleted + '%' }"
        />
      </div>

      <span
        class="text-sm sm:text-xl font-semibold text-gray-700 pb-2-5 sm:pb-7 z-30"
        >Uploading...</span
      >
      <div class="flex flex-col pr-5 z-30">
        <span class="text-tiny-xs sm:text-xs font-medium text-gray-700 pb-1-5"
          >{{ percentCompleted }}%</span
        >
        <k-progress
          :percent="percentCompleted"
          :line-height="5"
          :bg-color="'#EEF4FF'"
          :color="'#4476E4'"
          :show-text="false"
        ></k-progress>
      </div>
    </div>
    <div
      v-else-if="uploadState == STATES.FINISHED"
      class="flex justify-between w-full my-1-5 sm:my-2-5 p-2-5 sm:p-5 border border-gray-300 rounded"
    >
      <div class="flex w-8/9 justify-start">
        <icon-success />
        <div class="flex flex-col pl-2-5 sm:pl-5 text-left w-8/9">
          <span
            class="text-tiny-xs sm:text-base text-gray-700 font-medium truncate"
            >{{ fileName }}
          </span>
          <span class="text-xxs sm:text-xs text-gray-500"
            >Uploading complete
          </span>
        </div>
      </div>
      <div
        class="justify-end place-self-center cursor-pointer"
        @click="removeFile"
      >
        <icon-trash />
      </div>
    </div>
    <div
      v-else
      class="flex flex-col justify-between w-full my-1-5 sm:my-2-5 p-2-5 sm:p-5 border-2 border-gray-300 border-dashed rounded"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="drop"
    >
      <div class="flex justify-between">
        <div class="flex justify-start">
          <icon-error />
          <div class="flex flex-col pl-5 text-left justify-center">
            <span class="text-gray-700 font-medium">{{ fileName }} </span>
            <span class="text-tiny-xs sm:text-xs text-gray-500"
              >Your file is too big!</span
            >
          </div>
        </div>
        <div
          class="justify-end w-17 h-5 place-self-center bg-indigo-100 rounded-full font-medium text-tiny-xs sm:text-xs text-blue-700 cursor-pointer"
        >
          <label class="cursor-pointer">
            Reload
            <input
              id="file-upload"
              name="file-upload"
              type="file"
              class="sr-only"
              :value="file"
              accept=".doc, .pdf, .xls, .xlsx, image/*, .docx"
              @input="uploadFile"
            />
          </label>
        </div>
      </div>
      <span
        class="text-xxs sm:text-xs text-gray-500 pr-1-5 sm:pr-5 pt-1-5 sm:pt-5 text-justify"
        >Please upload a PDF of your bank statement. You can download a PDF of
        your bank statement from your online banking.
      </span>
    </div>
  </div>
</template>
<script type="text/javascript">
import { ref } from "vue";
import { useStore } from "vuex";
import WidgetApiService from "../../api-services/widget-api.service";
import { ALERT_TYPE } from "../constants";
import IconFile from "@/components/icons/IconFile";
import IconSuccess from "@/components/icons/IconSuccess";
import IconError from "@/components/icons/IconError";
import IconTrash from "@/components/icons/IconTrash";

const VALID_FILE_TYPES = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "image/jpeg",
  "image/png"
];

export default {
  name: "lf-upload",
  components: {
    IconFile,
    IconSuccess,
    IconError,
    IconTrash
  },
  props: {
    type: String,
    helpLabel: {
      type: String,
      default: ""
    },
    fileTypeName: String,
    acceptTypes: {
      type: String,
      default: ".doc, .pdf, .xls, .xlsx, image/*, .docx"
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const STATES = {
      INITIAL: 0,
      UPLOADING: 1,
      FINISHED: 2,
      ERROR: 3
    };

    const uploadState = ref(STATES.INITIAL);
    const percentCompleted = ref(0);
    const file = ref(null);
    const fileName = ref("");

    async function uploadFile(event) {
      const uploadedFile = event.target.files
        ? event.target.files[0]
        : event.dataTransfer.items[0].getAsFile();

      fileName.value = uploadedFile.name;

      if (!VALID_FILE_TYPES.includes(uploadedFile.type)) {
        store.commit(
          "setGlobalMessage",
          { type: ALERT_TYPE.ERROR, text: "Invalid file type!" },
          { root: true }
        );
      } else if (uploadedFile.size > 51200000) {
        uploadState.value = STATES.ERROR;
        store.commit(
          "setGlobalMessage",
          { type: ALERT_TYPE.ERROR, text: "File too large!" },
          { root: true }
        );
      } else {
        uploadState.value = STATES.UPLOADING;

        try {
          await WidgetApiService.uploadFile(
            store.state.business.applicationId,
            uploadedFile,
            props.fileTypeName,
            {
              onUploadProgress: progressEvent => {
                let progress = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                percentCompleted.value = progress;
              }
            }
          );
          uploadState.value = STATES.FINISHED;
          emit("upload-complete", fileName.value);
        } catch (error) {
          store.commit(
            "setGlobalMessage",
            {
              type: ALERT_TYPE.ERROR,
              text: error.response.data.errors.file[0]
            },
            { root: true }
          );
          uploadState.value = STATES.ERROR;
          return error;
        } finally {
          percentCompleted.value = 0;
        }
      }
    }

    function removeFile() {
      uploadState.value = STATES.INITIAL;
      fileName.value = "";
      emit("upload-complete", "");
    }

    function dragOver(event) {
      if (event.currentTarget.classList.contains("bg-gray-100")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-blue-200");
      }
    }

    function dragLeave(event) {
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-blue-200");
    }

    function drop(event) {
      uploadFile(event);
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-blue-200");
    }

    return {
      STATES,
      uploadState,
      percentCompleted,
      uploadFile,
      removeFile,
      fileName,
      dragOver,
      dragLeave,
      drop,
      file
    };
  }
};
</script>
<style scoped>
.k-progress {
  margin-right: -50px;
}
</style>
