import { render } from "./business.vue?vue&type=template&id=5dd95360"
import script from "./business.vue?vue&type=script&lang=js"
export * from "./business.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5dd95360"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5dd95360', script)) {
    api.reload('5dd95360', script)
  }
  
  module.hot.accept("./business.vue?vue&type=template&id=5dd95360", () => {
    api.rerender('5dd95360', render)
  })

}

script.__file = "src/components/page/business.vue"

export default script