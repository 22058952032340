<template>
  <Form v-slot="{ errors }" @keydown.enter.prevent @submit="nextStep">
    <div class="body-wrapper body-wrapper--step">
      <div class="section section--less-margin">
        <div class="section__title">Personal details</div>
      </div>

      <div class="section section--less-margin">
        <div class="section__title section__title--no-subtitle">
          Personal Address
        </div>

        <div class="section__body">
          <div class="btn-group btn-group-toggle d-flex">
            <div class="row">
              <div class="col-12 mb-3">
                <Field
                  name="address"
                  as="auto-complete"
                  v-model="personalAddress.address"
                  placeholder="Personal address"
                  rules="required"
                  :errorMessage="errors.address"
                  helpLabel="Personal address"
                  type="address"
                  @update:selectSuggestion="onAddressSelect"
                  :getAsyncOptions="getSmartyStreet"
                  :mapFunction="mapStreets"
                />
              </div>

              <div class="col-12 mb-3">
                <Field
                  name="secondAddress"
                  as="lf-input"
                  v-model="personalAddress.secondAddress"
                  class="w-full h-10 sm:h-13 my-1 sm:my-2-5"
                  placeholder="Personal address line 2"
                  :errorMessage="errors.secondAddress"
                  helpLabel="Personal address line 2"
                  type="secondAddress"
                  :autocomplete="true"
                />
              </div>

              <div class="col-12 col-lg-3 col-md-6 mb-3">
                <Field
                  name="state"
                  as="lf-dropdown-input"
                  v-model="personalAddress.state"
                  class="w-full h-10 sm:h-13 my-1 sm:my-2-5 mr-2 sm:mr-0"
                  placeholder="State"
                  :errorMessage="errors.state"
                  helpLabel="State"
                  type="state"
                  :options="states"
                  :autocomplete="true"
                />
              </div>

              <div class="col-12 col-lg-3 col-md-6 mb-3">
                <Field
                  name="city"
                  as="lf-input"
                  v-model="personalAddress.city"
                  class="w-full h-10 sm:h-13 my-1 sm:my-2-5"
                  placeholder="City"
                  :errorMessage="errors.city"
                  helpLabel="City"
                  type="city"
                  maxlength="50"
                  :autocomplete="true"
                  rules="required|name"
                />
              </div>

              <div class="col-12 col-lg-3 col-md-6 mb-3">
                <Field
                  name="zip"
                  as="lf-input"
                  v-model="personalAddress.zip"
                  class="w-full h-10 sm:h-13 my-1 sm:my-2-5"
                  placeholder="ZIP"
                  :errorMessage="errors.zip"
                  helpLabel="ZIP"
                  type="zip"
                  maxlength="10"
                  :autocomplete="true"
                  rules="required|numeric"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section section--less-margin">
        <div class="section__title section__title--no-subtitle">
          Date of Birth
        </div>
        <div class="section__body">
          <div class="btn-group btn-group-toggle d-flex">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <div class="flex">
                  <div class="flex-item">
                    <lf-dropdown
                      v-model="year"
                      class="w-40 h-13"
                      :options="yearRange"
                      placeholder="Year"
                    >
                    </lf-dropdown>
                  </div>
                  <div class="flex-item">
                    <lf-dropdown
                      v-model="month"
                      class="w-42 h-13 ml-2-5 mr-2-5"
                      :options="monthRange"
                      placeholder="Month"
                    >
                    </lf-dropdown>
                  </div>
                  <div class="flex-item">
                    <lf-dropdown
                      v-model="day"
                      class="w-40 h-13"
                      :options="dayRange"
                      placeholder="Day"
                    >
                    </lf-dropdown>
                  </div>
                </div>
                <div v-show="submitted" class="text-red-700 text-sm mt-2">
                  {{ errorMessageDate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section section--less-margin">
        <div class="section__title section__title--no-subtitle">
          Business Tax ID Number (EIN)
        </div>
        <div class="section__body">
          <div class="btn-group btn-group-toggle d-flex">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <Field
                  as="lf-input"
                  name="ein"
                  placeholder="EIN"
                  class="es-field w-full"
                  v-mask="{ mask: maskEin }"
                  v-model="ein"
                  :errorMessage="errors.ein"
                  helpLabel="EIN"
                  type="password"
                  :controlInputVisibility="true"
                  :nomargin="true"
                  rules="required|ein"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section section--less-margin">
        <div class="section__title section__title--no-subtitle">
          Owner Social Security Number (SSN)
        </div>
        <div class="section__body">
          <div class="btn-group btn-group-toggle d-flex">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <Field
                  as="lf-input"
                  name="ssn"
                  class="es-field w-full mb-2-5 sm:mb-5"
                  placeholder="SSN"
                  v-mask="{ mask: maskSsn }"
                  v-model="ssn"
                  :errorMessage="errors.ssn"
                  helpLabel="SSN"
                  type="password"
                  :controlInputVisibility="true"
                  :nomargin="true"
                  rules="required|ssn"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section section--less-margin">
        <div class="section__title section__title--no-subtitle">
          How could you best use the funds?
        </div>
        <div class="section__body">
          <lf-button-select
            :options="stepOptionsUseOfFunds"
            :selectedOption="selectedOptionUseOfFunds"
            @selected="setUseOfFunds"
          />
        </div>
      </div>

      <div class="section section--less-margin">
        <div class="section__title section__title--no-subtitle">
          When could you use the money?
        </div>
        <div class="section__body">
          <lf-button-select
            :options="stepOptionsDeliveryOfFunds"
            :selectedOption="selectedOptionDeliveryOfFunds"
            @selected="setDeliveryOfFunds"
          />
        </div>
      </div>
      <div
        class="section section--less-margin"
        v-if="Object.keys(errors).length > 0"
      >
        <span class="text-red-700 text-2xl"
          >Please complete all required fields to proceed to the next
          step.</span
        >
      </div>
      <div class="section section--less-margin" v-if="errorMessageDate && submitted">
        <span class="text-red-700 text-2xl"
          >Please choose right Date of Birthday.</span
        >
      </div>
    </div>
    <div class="submit">
      <button type="button" class="btn btn--confirm btn--cancel" @click="back">
        Back
      </button>
      <button type="submit" class="btn btn--confirm btn--submit">
        Next
      </button>
    </div>
  </Form>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed, watch } from "vue";
import { Form, Field } from "vee-validate";
import { smartyStreetsRequest } from "../helpers";
import { moveToSecondInput } from "../helpers.js";
import { maska } from "maska";
import Dropdown from "@/components/ui/Dropdown";
import ButtonSelect from "@/components/ui/ButtonSelect";
import _ from "lodash";

export default {
  name: "personal",
  components: {
    Form,
    Field,
    "lf-dropdown": Dropdown,
    "lf-button-select": ButtonSelect
  },
  directives: { mask: maska },
  setup() {
    window.scrollTo({ top: 0 });

    const store = useStore();

    store.dispatch("setProgress", 40);

    const personalAddress = ref(store.state.business.personalAddress);
    personalAddress.value.country = "United States";

    const states = computed(() =>
      Object.values(
        store.getters["customizationMetadata/all"].widget_customizations.states
      )
    );

    const stateCodes = computed(() =>
      Object.keys(
        store.getters["customizationMetadata/all"].widget_customizations.states
      )
    );

    async function back() {
      store.commit("backScreen");
    }

    async function nextStep() {
      submitted.value = true;
      if (isDOBselected.value && isAgeOfMajority.value) {
        store.commit("business/setPersonalAddress", personalAddress.value);

        store.commit("business/setEin", ein.value);
        store.commit("business/setSsn", ssn.value);
        store.commit("business/setDateOfBirth", {
          year: parseInt(year.value),
          month: parseInt(monthNum.value),
          day: parseInt(day.value),
          monthW: month.value
        });

        await store.dispatch("business/storePersonalAddress");
        await store.dispatch("business/storeEinSSn");
        await store.dispatch("business/storeFundUse");
        store.commit("nextScreen");
      }
    }

    function onAddressSelect(suggestions) {
      personalAddress.value.address = suggestions.street_line;
      personalAddress.value.secondAddress = suggestions.secondary;
      personalAddress.value.country = "US";
      personalAddress.value.city = suggestions.city;
      personalAddress.value.state = suggestions.state;
      personalAddress.value.zip = suggestions.zipcode;
      moveToSecondInput();
    }

    async function getSmartyStreet() {
      const include_only_states = stateCodes.value.join(";");
      const response = await smartyStreetsRequest({
        search: personalAddress.value.address,
        include_only_states
      });
      return response.data.suggestions;
    }

    function mapStreets(suggestions) {
      return suggestions.map(x => ({
        street: x.street_line,
        city: x.city,
        state: x.state
      }));
    }

    //Date of Birth day
    const dateOfBirth = ref(store.state.business.dateOfBirth);
    const month = ref(dateOfBirth.value.monthW);
    const year = ref(dateOfBirth.value.year);
    const day = ref(dateOfBirth.value.day);

    const submitted = ref(false);

    const monthNum = computed(
      () => month.value && month.value.match(/^(\d)+/)[0].trim()
    );

    const monthRange = [
      "1 - January",
      "2 - February",
      "3 - March",
      "4 - April",
      "5 - May",
      "6 - June",
      "7 - July",
      "8 - August",
      "9 - September",
      "10 - October",
      "11 - November",
      "12 - December"
    ];

    //change submitted to false if user picks new date
    watch(
      [month, year, day],
      ([monthNew, yearNew, dayNew], [prevMonth, prevYear, prevDay]) => {
        if (
          monthNew !== prevMonth ||
          yearNew !== prevYear ||
          dayNew !== prevDay
        ) {
          submitted.value = false;
        }
        if (
          monthNew !== "" &&
          yearNew !== "" &&
          (monthNew !== prevMonth || yearNew !== prevYear)
        ) {
          var days = new Date(yearNew, parseInt(monthNew), 0);
          dayRange.value = _.range(1, days.getDate() + 1);
          if (day.value !== "" && day.value > days.getDate()) {
            day.value = "";
          }
        }
      }
    );

    const dayRange = ref(_.range(1, 32));
    const errorMessageDate = computed(() => {
      if (!year.value && !month.value && !day.value)
        return "Please select a date!";
      else if (!year.value) return "Please select a year!";
      else if (!month.value) return "Please select a month!";
      else if (!day.value) return "Please select a day!";
      else if (!isAgeOfMajority.value)
        return "Sorry, you must be at least 18 to apply!";
      else return "";
    });

    const isDOBselected = computed(
      () => year.value && month.value && day.value
    );

    const isAgeOfMajority = computed(() => {
      const dob = new Date(year.value, parseInt(monthNum.value) - 1, day.value);
      return calculateAge(dob) >= 18;
    });

    //dob as Date object
    const calculateAge = dob => {
      //# of milliseconds in a year
      const msecPerYear = 3.15576e10;
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      return Math.floor(
        (today.getTime() - new Date(dob).getTime()) / msecPerYear
      );
    };

    function getValidYearMin() {
      return new Date().getFullYear();
    }

    //min year displayed in dropdown
    const startYear = 1899;

    //ein and ssn
    const ein = ref(store.state.business.ein);
    const ssn = ref(store.state.business.ssn);

    //use of funds
    const selectedOptionUseOfFunds = computed(
      () => store.state.business.useOfFunds
    );
    function setUseOfFunds(value) {
      store.commit("business/setUseOfFunds", value);
    }
    const stepOptionsUseOfFunds = computed(
      () =>
        store.getters["customizationMetadata/input"](
          store.state.options.use_of_funds
        ).use_of_funds.options
    );

    //delivery Of Funds
    const selectedOptionDeliveryOfFunds = computed(
      () => store.state.business.deliveryOfFunds
    );
    function setDeliveryOfFunds(value) {
      store.commit("business/setDeliveryOfFunds", value);
    }
    const stepOptionsDeliveryOfFunds = computed(
      () =>
        store.getters["customizationMetadata/input"](
          store.state.options.funds_needed_time
        ).funds_needed_time.options
    );

    return {
      nextStep,
      back,
      personalAddress,
      onAddressSelect,
      mapStreets,
      getSmartyStreet,
      states,

      dateOfBirth,
      day,
      month,
      year,
      dayRange: dayRange,
      monthRange,
      yearRange: _.range(getValidYearMin(), startYear),
      isAgeOfMajority,
      isDOBselected,
      errorMessageDate,
      submitted,

      ein,
      ssn,
      maskEin: "##-#######",
      maskSsn: "###-##-####",

      selectedOptionUseOfFunds,
      setUseOfFunds,
      stepOptionsUseOfFunds,

      selectedOptionDeliveryOfFunds,
      setDeliveryOfFunds,
      stepOptionsDeliveryOfFunds
    };
  }
};
</script>

<style scoped></style>
