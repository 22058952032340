import { render } from "./Slider.vue?vue&type=template&id=45cf3a08"
import script from "./Slider.vue?vue&type=script&lang=js"
export * from "./Slider.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "45cf3a08"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('45cf3a08', script)) {
    api.reload('45cf3a08', script)
  }
  
  module.hot.accept("./Slider.vue?vue&type=template&id=45cf3a08", () => {
    api.rerender('45cf3a08', render)
  })

}

script.__file = "src/components/ui/Slider.vue"

export default script