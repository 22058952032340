<template>
  <div v-if="isLoading">
    <lf-loader
      v-if="!docuSignLink"
      class="flex justify-center bg-white"
      :isDocuSign="true"
    />
    <div v-else class="my-32">
      <span
        :style="{
          color: customization.description_font_colour
        }"
      >
        Loading DocuSign...
      </span>
    </div>
  </div>
  <div class="body-wrapper body-wrapper--step">
    <div class="section section--less-margin">
      <div class="section__body">
        <div class="h-screen overflow-auto z-50">
          <iframe :src="docuSignLink" class="w-full h-full"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import LfLoader from "../ui/LfLoader";

export default {
  components: {
    LfLoader
  },
  setup() {
    const store = useStore();
    const isLoading = ref(true);
    const customization = {
      label_font_colour: "#fffffff",
      description_font_colour: "#000000"
    };

    store.dispatch("setProgress", 60);

    const appId = store.getters["business/applicationId"];
    onMounted(async () => {
      try {
        await store.dispatch("business/setWidgetLinks", { appId });
      } catch (error) {
        if (error.response.status === 403) {
          console.log(error.response.data.message);
        }
      } finally {
        isLoading.value = false;
      }
    });

    const docuSignLink = computed(() => store.state.business.docuSignLink);

    // onMounted(() => {
    //   store.commit("business/setDocuSignLink", "");
    //   isLoading.value = true;
    // });

    function checkIfDocSigned(event) {
      if (event.data === "docsigned") {
        isLoading.value = false;
        store.commit("nextScreen");
      }
    }

    window.addEventListener("message", checkIfDocSigned, false);

    onUnmounted(() => {
      window.removeEventListener("message", checkIfDocSigned);
    });

    return {
      isLoading,
      customization,
      docuSignLink
    };
  }
};
</script>
