import WidgetApiService from "../../api-services/widget-api.service";

const state = {
  customizationMetadata: {}
};

const mutations = {
  setCustomizationMetadata(state, customizationMetadata) {
    state.customizationMetadata = customizationMetadata;
  }
};

const actions = {
  async getCustomizationMetaData({ commit }) {
    const data = await WidgetApiService.getPreQualificationMetaData(
      process.env.VUE_APP_WIDGET_TOKEN
    );
    return commit("setCustomizationMetadata", data);
  }
};

const getters = {
  all(state) {
    return state.customizationMetadata;
  },
  customization(state) {
    return state.customizationMetadata["widget_customizations"];
  },
  title(state) {
    return step => {
      step = state.customizationMetadata[step];
      return step ? step.main_title : "";
    };
  },
  subTitle(state) {
    return step => {
      step = state.customizationMetadata[step];
      return step ? step.sub_title : "";
    };
  },
  buttonText(state) {
    return step => {
      step = state.customizationMetadata[step];
      return step ? step.button : "";
    };
  },
  subButtonText(state) {
    return step => {
      step = state.customizationMetadata[step];
      return step ? step.sub_button : "";
    };
  },
  footerText(state) {
    return step => {
      step = state.customizationMetadata[step];
      return step ? step.footer_text : "";
    };
  },
  input(state) {
    return step => {
      step = state.customizationMetadata[step];
      return step ? step.input : "";
    };
  },
  businessCreditServices(state) {
    return state.customizationMetadata["business_credit_services"];
  }
};

export const customizationMetadata = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
