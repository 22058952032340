import { render } from "./AutoComplete.vue?vue&type=template&id=25e70463"
import script from "./AutoComplete.vue?vue&type=script&lang=js"
export * from "./AutoComplete.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "25e70463"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('25e70463', script)) {
    api.reload('25e70463', script)
  }
  
  module.hot.accept("./AutoComplete.vue?vue&type=template&id=25e70463", () => {
    api.rerender('25e70463', render)
  })

}

script.__file = "src/components/ui/AutoComplete.vue"

export default script