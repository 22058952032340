import { render } from "./App.vue?vue&type=template&id=7ba5bd90"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "7ba5bd90"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7ba5bd90', script)) {
    api.reload('7ba5bd90', script)
  }
  
  module.hot.accept("./App.vue?vue&type=template&id=7ba5bd90", () => {
    api.rerender('7ba5bd90', render)
  })

}

script.__file = "src/App.vue"

export default script