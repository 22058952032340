<template>
  <div class="h-100">
    <div class="body-wrapper--center">
      <div class="row">
        <div class="col">
          <div class="font-semibold h1 m-4">Procore Platform Data</div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <img src="~@/assets/procore_page.png" alt="" class="img-fluid" />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <button class="btn--submit" @click="procoreAccess">
            Allow access to Procore Platform data
          </button>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <button class="btn--procore" @click="beginProcess">
            Continue without leveraging Procore Data
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "procorePage",
  setup() {
    const store = useStore();
    store.dispatch("setLoading", true);

    const procore = store.getters["business/getProcore"];

    if (window.location.hash.match(/access_token/) != null) {
      procoreAccess();
    }

    function beginProcess() {
      store.dispatch("setLoading", false);
      store.commit("nextScreen");
    }

    function procoreAccess() {
      const qParams = new URLSearchParams(window.location.search);
      if (qParams.has("procoreCompanyId")) {
        try {
          procore.initilaze(function(data) {
            if (data.action == "error") {
              console.log(data.message);
              store.commit("nextScreen");
            } else {
              procore.getData(
                data.access_token,
                qParams.get("procoreCompanyId"),
                qParams.get("procoreProjectId")
              );
              store.commit("nextScreen");
            }
          });
          procore.getAuth();
        } catch (e) {
          console.log(e);
          store.commit("nextScreen");
        }
      }

      store.dispatch("setLoading", false);
    }

    return {
      beginProcess,
      procoreAccess
    };
  }
};
</script>

<style scoped>
.btn--procore {
  color: rgb(254, 74, 27);
}
</style>
