import { render } from "./pre-qualification.vue?vue&type=template&id=15d1d855"
import script from "./pre-qualification.vue?vue&type=script&lang=js"
export * from "./pre-qualification.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "15d1d855"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('15d1d855', script)) {
    api.reload('15d1d855', script)
  }
  
  module.hot.accept("./pre-qualification.vue?vue&type=template&id=15d1d855", () => {
    api.rerender('15d1d855', render)
  })

}

script.__file = "src/components/page/pre-qualification.vue"

export default script