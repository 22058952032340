<template>
  <div class="container">
    <div class="body-wrapper--center">
      <div class="row mt-3">
        <div class="col"><img src="~@/assets/logo_page.png" alt="" /></div>
      </div>
    </div>
    <div
      class="body-wrapper body-wrapper--no-bottom-padding body-wrapper--step pt-1 mt-3"
      v-if="!isLoading"
    >
      <k-progress
        :percent="percent"
        :line-height="progressBarOptions.line_height"
        :bg-color="progressBarOptions.bg_color"
        :color="progressBarOptions.color"
      />
    </div>
  </div>
  <div class="container">
    <component :is="currentComponent"></component>
  </div>
</template>

<script>
import { useStore } from "vuex";
import preQualification from "./components/page/pre-qualification.vue";
import procorePage from "./components/page/procorePage.vue";
import business from "./components/page/business.vue";
import personal from "./components/page/personal.vue";
import docuSign from "./components/page/docuSign.vue";
import plaid from "./components/page/plaid.vue";
import bankstatements from "./components/page/bankstatements.vue";
import done from "./components/page/done.vue";

import { computed, onMounted } from "vue";
//import { ref } from "vue";

export default {
  name: "App",
  components: {
    procorePage,
    preQualification,
    business,
    personal,
    docuSign,
    plaid,
    bankstatements,
    done
  },
  setup() {
    const store = useStore();
    const percent = computed(() => store.getters.getProgressPercent);
    const isLoading = computed(() => store.getters.getLoading);

    const currentComponent = computed(() => store.getters["getActiveScreen"]);

    const progressBarOptions = {
      line_height: 32,
      bg_color: "#EBF0F4",
      color: "#FE4A1B"
    };

    onMounted(async () => {
      try {
        await store.dispatch("customizationMetadata/getCustomizationMetaData");
      } catch (error) {
        if (error.response.status === 403) {
          console.log(error.response.data.message);
        }
      }
    });

    return {
      isLoading,
      percent,
      progressBarOptions,
      currentComponent
    };
  }
};
</script>

<style></style>
