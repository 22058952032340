import { render } from "./ButtonSelect.vue?vue&type=template&id=23167829"
import script from "./ButtonSelect.vue?vue&type=script&lang=js"
export * from "./ButtonSelect.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "23167829"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('23167829', script)) {
    api.reload('23167829', script)
  }
  
  module.hot.accept("./ButtonSelect.vue?vue&type=template&id=23167829", () => {
    api.rerender('23167829', render)
  })

}

script.__file = "src/components/ui/ButtonSelect.vue"

export default script