<template>
  <div>
    <vue-slider v-model="sliderValue" v-bind="options"></vue-slider>
  </div>
</template>

<script>
import { computed, reactive, ref, watch } from "vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  // slider component represnets range min to midPointVal (eg. 0 to 150,000) for the first 50% of the slider length and mdpointVal to max (eg. 150,000 to 1,000,000) for the second part
  name: "lf-slider",
  components: {
    VueSlider
  },
  props: {
    modelValue: Number,
    //value at the middle of the slider
    midPointVal: Number,
    min: [Number, String],
    max: [Number, String]
  },
  setup(props, { emit }) {
    const modelValueInt = parseInt(props.modelValue);
    const maxInt = parseInt(props.max);
    const minInt = parseInt(props.min);
    const midPointInt = parseInt(props.midPointVal);

    const backgroundColor = "#FE4A1B";

    //convert loan value to slider range
    const initialSliderVal = computed(() => {
      return modelValueInt <= midPointInt
        ? convertRangeVal(
            modelValueInt,
            maxInt / 2,
            minInt,
            minInt,
            midPointInt
          )
        : convertRangeVal(
            modelValueInt,
            maxInt,
            maxInt / 2,
            midPointInt,
            maxInt
          );
    });

    const sliderValue = ref(initialSliderVal.value);

    const options = reactive({
      dotSize: 24,
      width: "auto",
      height: 8,
      min: parseInt(props.min),
      max: parseInt(props.max),
      interval: 1000,
      contained: true,
      tooltip: "hover",
      tooltipFormatter: "+",
      tooltipStyle: {
        borderTopColor: "white",
        backgroundColor: "white",
        color: "black",
        marginTop: "10%"
      },
      dotStyle: {
        backgroundColor: backgroundColor,
        border: "8px solid white"
      },
      processStyle: {
        backgroundColor: backgroundColor
      },
      railStyle: {
        backgroundColor: "whitesmoke"
      }
    });

    //convert slider value to loan amount
    const loanAmount = computed(() => {
      return sliderValue.value <= maxInt / 2
        ? convertRangeVal(
            sliderValue.value,
            midPointInt,
            minInt,
            minInt,
            maxInt / 2
          )
        : convertRangeVal(
            sliderValue.value,
            maxInt,
            midPointInt,
            maxInt / 2,
            maxInt
          );
    });

    watch(
      () => loanAmount.value,
      (newLoanAmount, prevLoanAmount) => {
        if (newLoanAmount !== prevLoanAmount)
          emit("update:modelValue", newLoanAmount);
      }
    );

    //convert values in one range to values in another range (rounded to 1000)
    function convertRangeVal(
      inputVal,
      outputRangeEnd,
      outputRangeStart,
      inputRangeStart,
      inputRangeEnd
    ) {
      const convertedVal =
        outputRangeStart +
        ((outputRangeEnd - outputRangeStart) /
          (inputRangeEnd - inputRangeStart)) *
          (inputVal - inputRangeStart);

      return Math.round(convertedVal / 1000) * 1000;
    }

    return {
      sliderValue,
      options
    };
  }
};
</script>
