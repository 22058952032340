<template>
  <div>
    <div class="body-wrapper body-wrapper--step">
      <div class="section">
        <div class="section__head">
          <div class="section__left">
            <div class="section__title">Loan amount</div>
            <div class="section__subtitle">
              Select amount of money you want to borrow
            </div>
          </div>
          <input
            type="text"
            v-model="loan_amount"
            class="section__amount"
            placeholder="$0"
            readonly
          />
        </div>
        <div class="section__body">
          <div class="section__progress-bar">
            <div class="range">
              <lf-slider
                v-model="requested_amount"
                :midPointVal="150_000"
                :min="1000"
                :max="500000"
              />
              <ul class="range__labels">
                <li>$1000</li>
                <li>
                  <p><span></span>$100000</p>
                </li>
                <li>
                  <p><span></span>$200000</p>
                </li>
                <li>
                  <p><span></span>$250000</p>
                </li>
                <li>
                  <p><span></span>$300000</p>
                </li>
                <li>
                  <p><span></span>$400000</p>
                </li>
              </ul>
              <div class="range__last">$500000+</div>
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="section__title">Time in Business</div>
        <div class="section__subtitle">
          How long have you been operating your business?
        </div>
        <div class="section__body">
          <lf-button-select
            :options="stepOptionsTimeInBusiness"
            :selectedOption="selectedOptionTimeInBusiness"
            @selected="setTimeInBusiness"
          />
        </div>
      </div>

      <div class="section">
        <div class="section__title">Monthly Revenue</div>
        <div class="section__subtitle">
          What is your average monthly revenue over the past 3 months?
        </div>
        <div class="section__body">
          <lf-button-select
            :options="stepOptionsMonthlyAvgRevenue.mapped"
            :selectedOption="selectedOptionMonthlyAvgRevenue"
            @selected="setMonthlyAvgRevenue"
          />
        </div>
      </div>

      <div class="section">
        <div class="section__title">Personal Credit Score</div>
        <div class="section__subtitle">
          What is your estimated personal credit score?
        </div>
        <div class="section__body">
          <lf-button-select
            :options="stepOptionsCreditScore"
            :selectedOption="selectedOptionCreditScore"
            @selected="setCreditScore"
          />
        </div>
      </div>
    </div>
    <div class="section"><p v-show="errorMessage"></p></div>
    <div class="submit">
      <div class="submit__terms">
        By selecting "Next" you agree to our
        <a
          href="https://docs.google.com/document/u/1/d/e/2PACX-1vRM57l8G7IwCsFSD-E4Cp_AIv_BNPbWPqyxJ6rWHlA5Zhy50A4W0dambrC-zxzEsNOzzcoZUHZcPuDm/pub"
          target="_blank"
          >Terms of Service</a
        >
        and
        <a
          href="https://docs.google.com/document/u/1/d/e/2PACX-1vTYHkkRmGmwBlIh-_9VfmCLHSMDNmIYJOfqDQ8o_5zgofB4Y6on7MM6XfftmLDC2g/pub"
          target="_blank"
          >Privacy Policy</a
        >.
      </div>
      <button class="btn btn--confirm btn--submit" @click="submit">
        Next
      </button>
    </div>
  </div>
</template>

<script>
import "vue-slider-component/theme/default.css";
import Slider from "@/components/ui/Slider.vue";
import { formatMoney } from "../helpers.js";
import { useStore } from "vuex";
import { ref, watch } from "vue";
import { computed } from "vue";
import ButtonSelect from "@/components/ui/ButtonSelect";
// import { reactive } from "vue";

export default {
  name: "pre-qualification",
  components: {
    "lf-slider": Slider,
    "lf-button-select": ButtonSelect
  },
  setup() {
    const store = useStore();

    store.dispatch("setProgress", 0);
    store.dispatch("setLoading", false);

    const requested_amount = ref(store.state.values.loanAmount);
    const loan_amount = computed(() => formatMoney(requested_amount.value));

    //time in business
    const selectedOptionTimeInBusiness = computed(
      () => store.state.values.timeInBusiness
    );
    function setTimeInBusiness(value) {
      store.commit("values/setTimeInBusiness", value);
    }
    const stepOptionsTimeInBusiness = computed(
      () =>
        store.getters["customizationMetadata/all"][
          store.state.options.time_in_business
        ].input.time_in_business.options
    );

    //monthly avg revenue
    const selectedOptionMonthlyAvgRevenue = computed(() =>
      store.state.values.monthlyAvgRevenue.replace("monthly_avg_revenue_", "")
    );
    function setMonthlyAvgRevenue(value) {
      store.commit("values/setMonthlyAvgRevenue", value);
    }
    const stepOptionsMonthlyAvgRevenue = computed(() => {
      var options =
        store.getters["customizationMetadata/all"][
          store.state.options.monthly_avg_revenue
        ].input.monthly_avg_revenue.options;

      const mappedEntries = Object.entries(options).map(([key, value]) => [
        key.replace("monthly_avg_revenue_", ""),
        formatOptionValue(value)
      ]);
      return {
        original: options,
        mapped: Object.fromEntries(mappedEntries)
      };
    });

    // not defined as const so that it gets hoisted
    function formatOptionValue(value) {
      return value
        .split(" - ")
        .map(num =>
          num.includes("+")
            ? `${formatMoney(num.replace("+", ""))}+`
            : formatMoney(num)
        )
        .join(" to ");
    }

    store.commit(
      "values/setMonthlyAvgRevenueOptions",
      stepOptionsMonthlyAvgRevenue.value.original
    );
    //credit score
    const selectedOptionCreditScore = computed(
      () => store.state.values.creditScore
    );
    function setCreditScore(value) {
      store.commit("values/setCreditScore", value);
    }
    const stepOptionsCreditScore = computed(
      () =>
        store.getters["customizationMetadata/all"][
          store.state.options.credit_score
        ].input.credit_score.options
    );

    const errorMessage = ref("");

    watch(
      () => requested_amount.value,
      (loanAmount, prevLoanAmount) => {
        if (prevLoanAmount !== loanAmount) errorMessage.value = "";
      }
    );

    function submit() {
      store.commit("values/setLoanAmount", this.requested_amount);
      store.dispatch("values/getFundingOffer");
      store.commit("nextScreen");
    }

    return {
      submit,

      requested_amount,
      loan_amount,
      errorMessage,

      selectedOptionTimeInBusiness,
      setTimeInBusiness,
      stepOptionsTimeInBusiness,

      selectedOptionMonthlyAvgRevenue,
      setMonthlyAvgRevenue,
      stepOptionsMonthlyAvgRevenue,

      selectedOptionCreditScore,
      setCreditScore,
      stepOptionsCreditScore
    };
  }
};
</script>

<style scoped></style>
