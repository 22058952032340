import { createApp } from "vue";
import App from "./App.vue";

import store from "./store";
import "./assets/tailwind.css";
import KProgress from "k-progress-v3";
import "./vee-validate";
import Input from "./components/ui/Input";
import DropDownInput from "./components/ui/DropDownInput.vue";
import AutoComplete from "./components/ui/AutoComplete";
import { maska } from "maska";

window.axios = require("axios");
import "bootstrap";
import "./assets/css/style.css";
import "./assets/scss/style.scss";
import { procore } from "./components/procore";

async function run() {
  const app = createApp(App)
    .use(store)
    .component("k-progress", KProgress)
    .component("lf-input", Input)
    .component("auto-complete", AutoComplete)
    .component("lf-dropdown-input", DropDownInput);

  app.mount("#app");
  app.directive("mask", maska);
}

store.commit("business/setProcore", procore);

run();
