export const SMARTY_STREETS_ENDPOINT =
  "https://us-autocomplete-pro.api.smartystreets.com/lookup";

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const SSN_VALIDATE_REGEX = /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;
export const EIN_VALIDATE_REGEX = /^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])-?\d{7}$/;

export const ALERT_TYPE = {
  ERROR: "error",
  SUCCESS: "success"
};

export const termsOfService =
  "https://docs.google.com/document/u/1/d/e/2PACX-1vRM57l8G7IwCsFSD-E4Cp_AIv_BNPbWPqyxJ6rWHlA5Zhy50A4W0dambrC-zxzEsNOzzcoZUHZcPuDm/pub";

export const privacyPolicy =
  "https://docs.google.com/document/u/1/d/e/2PACX-1vTYHkkRmGmwBlIh-_9VfmCLHSMDNmIYJOfqDQ8o_5zgofB4Y6on7MM6XfftmLDC2g/pub";

export const sliderMidPoint = 150_000;

export const PRODUCTS = {
  1: "leadfunding",
  2: "docusign",
  3: "plaid",
  4: "credit-score",
  5: "docusign-plaid"
};

export const FINISHED_APP_PHASE = {
  BASIC: 1,
  FULL: 2,
  PLAID: 3,
  DOCUSIGN: 4,
  DOCU_PLAID: 5,
  BUSINESS_CREDIT: 6
};
