import store from "../store/index";

const PLAID_URL = "https://cdn.plaid.com/link/v2/stable/link-initialize.js";

export function loadScript() {
  return new Promise(function(resolve, reject) {
    if (document.querySelector('script[src="' + PLAID_URL + '"]')) {
      resolve();
      return;
    }
    const el = document.createElement("script");
    el.type = "text/javascript";
    el.async = true;
    el.src = PLAID_URL;
    el.addEventListener("load", resolve);
    el.addEventListener("error", reject);
    el.addEventListener("abort", reject);
    document.head.appendChild(el);
  });
}

export function storePlaidToken(plaidLink) {
  window.linkHandler = window.Plaid.create({
    token: plaidLink,
    env: process.env.VUE_APP_PLAID_ENV || "sandbox",
    onSuccess: async public_token => {
      store.commit("business/setPlaidToken", public_token);
      await store.dispatch("business/storePlaidToken");
      store.commit("nextScreen");
    }
  });
  window.linkHandler.open();
}
