import { render } from "./IconFile.vue?vue&type=template&id=8d5963cc"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "8d5963cc"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('8d5963cc', script)) {
    api.reload('8d5963cc', script)
  }
  
  module.hot.accept("./IconFile.vue?vue&type=template&id=8d5963cc", () => {
    api.rerender('8d5963cc', render)
  })

}

script.__file = "src/components/icons/IconFile.vue"

export default script