import { render } from "./done.vue?vue&type=template&id=a1fc6bbc"
import script from "./done.vue?vue&type=script&lang=js"
export * from "./done.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "a1fc6bbc"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('a1fc6bbc', script)) {
    api.reload('a1fc6bbc', script)
  }
  
  module.hot.accept("./done.vue?vue&type=template&id=a1fc6bbc", () => {
    api.rerender('a1fc6bbc', render)
  })

}

script.__file = "src/components/page/done.vue"

export default script