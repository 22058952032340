import WidgetApiService from "../../api-services/widget-api.service";
import { formatDate } from "../../components/helpers.js";

const state = {
  resumeState: "",
  applicationId: "",
  businessInfo: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    legalName: "",
    dba: ""
  },
  termsOfService: false,
  businessEntity: "business_entity_type_2",
  businessAddress: {
    address: "",
    secondAddress: "",
    country: "",
    city: "",
    state: "",
    zip: ""
  },
  isHomeBased: true,
  isSoleOwner: true,
  ownerPercentage: "",
  businessStartDate: {
    //numerical representation of the month
    month: "",
    year: "",
    //word representation that apears in the dropdown
    monthW: ""
  },
  personalAddress: {
    address: "",
    secondAddress: "",
    country: "",
    city: "",
    state: "",
    zip: ""
  },
  dateOfBirth: {
    day: "",
    //number representation of month
    month: "",
    year: "",
    //word representation of month
    monthW: ""
  },
  ein: "",
  ssn: "",
  useOfFunds: "use_of_funds_2",
  deliveryOfFunds: "funds_needed_time_2",
  docuSignLink: "",
  docIsSigned: false,
  plaidLink: "",
  plaidToken: "",
  documents: {},
  industryType: "",
  employeeCount: "",
  hasOutstandingDebt: null,
  accountReceivables: [],
  totalAvgDebt: null,
  hasInventory: null,
  inventoryType: "",
  hasWebsite: null,
  websiteAddress: "",
  hasEcommerceStore: null,
  ecommercePlatform: "",
  ecommerceWebsite: "",
  hasSubscriptions: null,
  ownsAssets: false,
  availableAssetTypes: [],
  assetTypes: [],
  assets: [],
  hasTaxReturn: false,
  taxReturn: {},
  hasAccountingSoftware: null,
  accountingSoftware: [],
  accountingSoftwareToken: "",
  hasFinancialStatements: false,
  financialStatements: [],
  receivableAgingReport: "",
  payableAgingReport: "",
  hasPayrollSoftware: null,
  payrollSoftware: [],
  hasBusinessDebt: null,
  businessDebt: null,
  personalIncome: null,
  dataServices: [],
  completedPhase: 0,
  procore: {}
};

const mutations = {
  setProcore(state, data) {
    state.procore = data;
  },
  setResumeState(state, resumeState) {
    state.resumeState = resumeState;
  },
  setApplicationId(state, applicationId) {
    state.applicationId = applicationId;
  },
  setBusinessInfo(state, businessInfo) {
    state.businessInfo = businessInfo;
  },
  setTermsOfService(state, terms) {
    state.termsOfService = terms;
  },
  setBusinessEntity(state, businessEntity) {
    state.businessEntity = businessEntity;
  },
  setBusinessAddress(state, businessAddress) {
    state.businessAddress = businessAddress;
  },
  setHomeBased(state, isHomeBased) {
    state.isHomeBased = isHomeBased;
  },
  setSoleOwner(state, isSoleOwner) {
    state.isSoleOwner = isSoleOwner;
  },
  setOwnerPercentage(state, ownerPercentage) {
    state.ownerPercentage = ownerPercentage;
  },
  setBusinessStartDate(state, date) {
    state.businessStartDate = date;
  },
  setPersonalAddress(state, personalAddress) {
    state.personalAddress = personalAddress;
  },
  setDateOfBirth(state, dateOfBirth) {
    state.dateOfBirth = dateOfBirth;
  },
  setEin(state, ein) {
    state.ein = ein;
  },
  setSsn(state, ssn) {
    state.ssn = ssn;
  },
  setUseOfFunds(state, useOfFunds) {
    state.useOfFunds = useOfFunds;
  },
  setDeliveryOfFunds(state, deliveryOfFunds) {
    state.deliveryOfFunds = deliveryOfFunds;
  },
  setDocuSignLink(state, docuSignLink) {
    state.docuSignLink = docuSignLink;
  },
  setDocIsSigned(state, docIsSigned) {
    state.docIsSigned = docIsSigned;
  },
  setPlaidLink(state, plaidLink) {
    state.plaidLink = plaidLink;
  },
  setPlaidToken(state, plaidToken) {
    state.plaidToken = plaidToken;
  },
  setDocuments(state, documents) {
    state.documents = documents;
  },
  setIndustryType(state, industryType) {
    state.industryType = industryType;
  },
  setEmployeeCount(state, employeeCount) {
    state.employeeCount = employeeCount;
  },
  setHasOutstandingDebt(state, hasOutstandingDebt) {
    state.hasOutstandingDebt = hasOutstandingDebt;
  },
  setAccountReceivables(state, accountReceivables) {
    state.accountReceivables = accountReceivables;
  },
  addAccountReceivable(state, accountReceivable) {
    state.accountReceivables.push(accountReceivable);
  },
  removeAccountReceivable(state, accountReceivable) {
    state.accountReceivables = state.accountReceivables.filter(
      item => item != accountReceivable
    );
  },
  setTotalAvgDebt(state, totalAvgDebt) {
    state.totalAvgDebt = totalAvgDebt;
  },
  setHasInventory(state, hasInventory) {
    state.hasInventory = hasInventory;
  },
  setInventoryType(state, inventoryType) {
    state.inventoryType = inventoryType;
  },
  setHasWebsite(state, hasWebsite) {
    state.hasWebsite = hasWebsite;
  },
  setWebsiteAddress(state, websiteAddress) {
    if (!websiteAddress.startsWith("http")) {
      websiteAddress = "https://" + websiteAddress;
    }
    state.websiteAddress = websiteAddress;
  },
  setHasEcommerceStore(state, hasEcommerceStore) {
    state.hasEcommerceStore = hasEcommerceStore;
  },
  setEcommercePlatform(state, ecommercePlatform) {
    state.ecommercePlatform = ecommercePlatform;
  },
  setEcommerceWebsite(state, ecommerceWebsite) {
    if (!ecommerceWebsite.startsWith("http")) {
      ecommerceWebsite = "https://" + ecommerceWebsite;
    }
    state.ecommerceWebsite = ecommerceWebsite;
  },
  setHasSubscriptions(state, hasSubscriptions) {
    state.hasSubscriptions = hasSubscriptions;
  },
  setOwnsAssets(state, ownsAssets) {
    state.ownsAssets = ownsAssets;
  },
  setAvailableAssetTypes(state, assetTypes) {
    state.availableAssetTypes = assetTypes;
  },
  addAssetType(state, assetType) {
    state.assetTypes.push(assetType);
  },
  removeAssetType(state, assetType) {
    state.assetTypes = state.assetTypes.filter(item => item !== assetType);
  },
  removeAllAssetTypes(state) {
    state.assetTypes = [];
  },
  addAllAssetTypes(state, assetTypes) {
    state.assetTypes = assetTypes;
  },
  initializeAssetObject(state, assets) {
    state.assets = assets;
  },
  addAsset(state, { assetType, asset }) {
    state.assets.push({
      asset_type: assetType,
      data: asset
    });
  },
  removeAsset(state, { assetType, asset }) {
    state.assets = state.assets.filter(
      item => item.assetType !== assetType && item.data !== asset
    );
  },
  setHasTaxReturn(state, hasTaxReturn) {
    state.hasTaxReturn = hasTaxReturn;
  },
  setTaxReturn(state, taxReturn) {
    state.taxReturn = taxReturn;
  },
  setHasAccountingSoftware(state, hasAccountingSoftware) {
    state.hasAccountingSoftware = hasAccountingSoftware;
  },
  addAccountingSoftware(state, accountingSoftware) {
    state.accountingSoftware.push(accountingSoftware);
  },
  removeAccountingSoftware(state, accountingSoftware) {
    state.accountingSoftware = state.accountingSoftware.filter(
      item => item != accountingSoftware
    );
  },
  removeAllAccountingSoftware(state) {
    state.accountingSoftware = [];
  },
  setAccountingSoftware(state, accountingSoftware) {
    state.accountingSoftware = accountingSoftware;
  },
  setHasFinancialStatements(state, hasFinancialStatements) {
    state.hasFinancialStatements = hasFinancialStatements;
  },
  setFinancialStatements(state, financialStatements) {
    state.financialStatements = financialStatements;
  },
  setReceivableAgingReport(state, receivableAgingReport) {
    state.receivableAgingReport = receivableAgingReport;
  },
  setPayableAgingReport(state, payableAgingReport) {
    state.payableAgingReport = payableAgingReport;
  },
  setHasPayrollSoftware(state, hasPayrollSoftware) {
    state.hasPayrollSoftware = hasPayrollSoftware;
  },
  setPayrollSoftware(state, payrollSoftware) {
    state.payrollSoftware = payrollSoftware;
  },
  addPayrollSoftware(state, payrollSoftware) {
    state.payrollSoftware.push(payrollSoftware);
  },
  removePayrollSoftware(state, payrollSoftware) {
    state.payrollSoftware = state.payrollSoftware.filter(
      item => item != payrollSoftware
    );
  },
  setHasBusinessDebt(state, hasBusinessDebt) {
    state.hasBusinessDebt = hasBusinessDebt;
  },
  setBusinessDebt(state, businessDebt) {
    state.businessDebt = businessDebt;
  },
  setPersonalIncome(state, personalIncome) {
    state.personalIncome = personalIncome;
  },
  setCompletedPhase(state, completedPhase) {
    state.completedPhase = completedPhase;
  },
  addDataService(state, dataService) {
    state.dataServices.push(dataService);
  },
  removeDataService(state, dataService) {
    state.dataServices = state.dataServices.filter(
      item => item !== dataService
    );
  },
  removeAllDataServices(state) {
    state.dataServices = [];
  },
  addAllDataServices(state, dataServices) {
    state.dataServices = dataServices;
  },
  setStateFromMetaData(state, metaData) {
    // TODO -> Data missing from the response is commented out
    const newState = {
      // resumeState: "",
      applicationId: metaData.id,
      businessInfo: {
        firstName: metaData.personal_information?.first_name,
        lastName: metaData.personal_information?.last_name,
        email: metaData.personal_information?.email_address,
        phone: metaData.personal_information?.telephone,
        legalName: metaData.business?.business_legal_name,
        dba: metaData.business?.doing_business_as
      },
      // termsOfService: false,
      businessEntity: metaData.business?.entity_type_id,
      businessAddress: {
        address: metaData.business_address?.address_line || "",
        secondAddress: metaData.business_address?.address_line2 || "",
        country: metaData.business_address?.country || "",
        city: metaData.business_address?.city || "",
        state: metaData.business_address?.state || "",
        zip: metaData.business_address?.zip || ""
      },
      // isHomeBased: true,
      // isSoleOwner: true,
      ownerPercentage: "",
      businessStartDate: {
        month: metaData.business.business_start_date?.split("-")[1],
        year: metaData.business.business_start_date?.split("-")[0]
      },
      personalAddress: {
        address: metaData.personal_address?.address_line,
        secondAddress: metaData.personal_address?.address_line2,
        country: metaData.personal_address?.country,
        city: metaData.personal_address?.city,
        state: metaData.personal_address?.state,
        zip: metaData.personal_address?.zip
      },
      dateOfBirth: {
        day: metaData.personal_information.date_of_birth?.split("-")[2],
        month: metaData.personal_information.date_of_birth?.split("-")[1],
        year: metaData.personal_information.date_of_birth?.split("-")[1]
      },
      // ein: "",
      // ssn: "",
      useOfFunds: metaData.use_of_funds,
      deliveryOfFunds: metaData.funds_needed_time,
      // docuSignLink: "",
      // docIsSigned: false,
      // plaidLink: "",
      plaidToken: metaData.plaid_token,
      // documents: {},
      // industryType: "",
      // employeeCount: "",
      hasOutstandingDebt: metaData.has_outstanding_debt,
      accountReceivables: metaData.account_receivables,
      totalAvgDebt: metaData.total_average_debt,
      hasInventory: metaData.holds_inventory,
      inventoryType: metaData.inventory_type,
      hasWebsite: metaData.business.has_website,
      // websiteAddress: "",
      hasEcommerceStore: metaData.business.is_ecommerce,
      ecommercePlatform: metaData.ecommerce_platform,
      // ecommerceWebsite: "",
      hasSubscriptions: metaData.has_subscriptions,
      // ownsAssets: false,
      // availableAssetTypes: [],
      // assetTypes: [],
      assets: metaData.assets,
      hasTaxReturn: metaData.has_tax_return,
      // taxReturn: {},
      // hasAccountingSoftware: false,
      accountingSoftware: metaData.accounting_software_used,
      accountingSoftwareToken: "",
      // hasFinancialStatements: false,
      financialStatements: [],
      // receivableAgingReport: "",
      // payableAgingReport: "",
      hasPayrollSoftware: metaData.has_payroll_software,
      payrollSoftware: metaData.payroll_softwares,
      // hasBusinessDebt: false,
      businessDebt: metaData.personal_income_total_debt,
      personalIncome: metaData.estimated_personal_income,
      // completedPhase: 0,
      files: metaData.files
    };
    Object.assign(state, newState);
  }
};

const actions = {
  async storeBusinessMetaData({ state }, descr) {
    await WidgetApiService.storeBusinessMetadata(
      {
        description: descr || "",
        metadata: state.procore.data
      },
      state.applicationId
    );
  },
  async storeBusinessInfo({ commit, rootState, state }) {
    const data = await WidgetApiService.storeBusinessInfo({
      first_name: state.businessInfo.firstName,
      last_name: state.businessInfo.lastName,
      email_address: state.businessInfo.email,
      telephone: state.businessInfo.phone,
      business_legal_name: state.businessInfo.legalName,
      doing_business_as: state.businessInfo.dba,
      prequalification_id: rootState.values.prequalificationId,
      terms_of_service: state.termsOfService
    });
    commit("setResumeState", data.resume_state);
    return commit("setApplicationId", data.application_id);
  },
  async storeBusinessAddress({ commit, state }) {
    const data = await WidgetApiService.storeBusinessAddress(
      {
        business_entity: state.businessEntity,
        business_address: state.businessAddress.address,
        business_address_2: state.businessAddress.secondAddress,
        business_city: state.businessAddress.city,
        business_state: state.businessAddress.state,
        business_country: state.businessAddress.country,
        business_postal_code: state.businessAddress.zip
      },
      state.applicationId
    );
    return commit("setResumeState", data.resume_state);
  },
  async storePersonalAddress({ commit, state }) {
    const { year, month } = state.businessStartDate;
    const data = await WidgetApiService.storePersonalAddress(
      {
        is_home_business: state.isHomeBased,
        is_sole_owner: state.isSoleOwner,
        owner_percentage: state.ownerPercentage,
        business_start_date: formatDate(year, month, 1),
        personal_address: state.personalAddress.address,
        personal_address_2: state.personalAddress.secondAddress,
        personal_city: state.personalAddress.city,
        personal_state: state.personalAddress.state,
        personal_country: state.personalAddress.country,
        personal_postal_code: state.personalAddress.zip
      },
      state.applicationId
    );
    return commit("setResumeState", data.resume_state);
  },
  async storeEinSSn({ commit, state }) {
    const data = await WidgetApiService.storeEinSSn(
      {
        date_of_birth: `${state.dateOfBirth.year}-${state.dateOfBirth.month}-${state.dateOfBirth.day}`,
        social_security_number: state.ssn,
        employee_identification_number: state.ein
      },
      state.applicationId
    );
    return commit("setResumeState", data.resume_state);
  },
  async storeFundUse({ commit, state }) {
    const data = await WidgetApiService.storeFundUse(
      {
        use_of_fund: state.useOfFunds,
        funds_needed_time: state.deliveryOfFunds
      },
      state.applicationId
    );
    commit("setDocuSignLink", data.docusign_link);
    commit("setPlaidLink", data.plaid_link_token);
    return commit("setResumeState", data.resume_state);
  },
  async storeDocumentSigned({ commit, state }) {
    const data = await WidgetApiService.storeDocumentSigned(
      state.applicationId
    );
    return commit("setResumeState", data.resume_state);
  },
  async storePlaidToken({ commit, state }) {
    const data = await WidgetApiService.storePlaidToken(
      {
        plaid_token: state.plaidToken
      },
      state.applicationId
    );
    return commit("setResumeState", data.resume_state);
  },
  async storeOutstandingDebt({ commit, state }) {
    const payload = {
      industry_type: state.industryType,
      number_of_employees: state.employeeCount,
      outstanding_debt: state.hasOutstandingDebt
    };
    if (state.hasOutstandingDebt) {
      payload["total_average_debt"] = state.totalAvgDebt;
      payload["account_receivables"] = state.accountReceivables;
    }

    const data = await WidgetApiService.storeOutstandingDebt(
      payload,
      state.applicationId
    );
    return commit("setResumeState", data.resume_state);
  },
  async storeWebsite({ commit, state }) {
    const payload = {
      holds_inventory: state.hasInventory,
      has_website: state.hasWebsite,
      is_ecommerce: state.hasEcommerceStore || false,
      has_subscriptions: state.hasSubscriptions || false
    };
    if (state.hasInventory) {
      payload["inventory_type"] = state.inventoryType;
    }
    if (state.hasWebsite) {
      payload["website_address"] = state.websiteAddress;
    }
    if (state.hasEcommerceStore) {
      payload["ecommerce_platform"] = state.ecommercePlatform;
      payload["ecommerce_website"] = state.ecommerceWebsite;
    }

    const data = await WidgetApiService.storeWebsite(
      payload,
      state.applicationId
    );
    return commit("setResumeState", data.resume_state);
  },
  async storeAssets({ commit, state }) {
    const payload = {
      owns_assets: state.ownsAssets
    };
    if (state.ownsAssets) {
      payload["assets"] = state.assets.map(asset => {
        return {
          asset_type: asset.asset_type,
          data: [
            ...Object.entries(asset.data).map(([k, v]) => ({
              asset_field: k,
              value: v
            }))
          ]
        };
      });
    }
    const data = await WidgetApiService.storeAssets(
      payload,
      state.applicationId
    );
    return commit("setResumeState", data.resume_state);
  },
  async storeTaxReturn({ commit, state }) {
    const data = await WidgetApiService.storeTaxReturn(
      {
        has_tax_return: state.hasTaxReturn
      },
      state.applicationId
    );
    return commit("setResumeState", data.resume_state);
  },
  async storePersonalIncome({ commit, state }) {
    const payload = {
      has_payroll_software: state.hasPayrollSoftware,
      estimated_personal_income: state.personalIncome
    };
    if (state.hasPayrollSoftware) {
      payload["payroll_softwares"] = state.payrollSoftware;
    }
    if (state.hasBusinessDebt) {
      payload["total_dept"] = state.businessDebt;
    }
    const data = await WidgetApiService.storePersonalIncome(
      payload,
      state.applicationId
    );
    return commit("setResumeState", data.resume_state);
  },
  async storeAccountingSoftware({ commit, state }) {
    let payload = {
      has_accounting_software: state.hasAccountingSoftware
    };
    if (state.hasAccountingSoftware) {
      payload["accounting_software_used"] = state.accountingSoftware;
    }

    const data = await WidgetApiService.storeAccountingSoftware(
      payload,
      state.applicationId
    );
    return commit("setResumeState", data.resume_state);
  },
  async finishApp() {
    let payload = {
      type: state.completedPhase
    };
    if (state.dataServices.length) {
      payload["requested_products"] = state.dataServices;
    }
    await WidgetApiService.finishApp(payload, state.applicationId);
  },
  async createBusinessCreditApp({ commit, state }) {
    const { year, month } = state.businessStartDate;
    const data = await WidgetApiService.createBusinessCreditApp({
      basic_info: {
        first_name: state.businessInfo.firstName,
        last_name: state.businessInfo.lastName,
        email_address: state.businessInfo.email,
        telephone: state.businessInfo.phone,
        doing_business_as: state.businessInfo.dba
      },
      business_address: {
        address_line: state.businessAddress.address,
        address_line2: state.businessAddress.secondAddress,
        country: state.businessAddress.country,
        city: state.businessAddress.city,
        state: state.businessAddress.state,
        zip: state.businessAddress.zip
      },
      business_start_date: formatDate(year, month, 1),
      business_entity: state.businessEntity,
      business_legal_name: state.businessInfo.legalName,
      employee_identification_number: state.ein,
      terms_of_service: state.termsOfService
    });
    return commit("setApplicationId", data.application_id);
  },
  async setWidgetLinks({ commit }, { appId, getDocusign, getPlaid }) {
    const data = await WidgetApiService.getWidgetLinks(
      appId,
      getDocusign,
      getPlaid
    );
    commit("setDocuSignLink", data.docusign_link);
    commit("setPlaidLink", data.plaid_link_token);
  }
};

const getters = {
  getProcore(state) {
    return state.procore;
  },
  applicationId(state) {
    return state.applicationId;
  },
  businessAddress(state) {
    return state.businessAddress;
  },
  personalAddress(state) {
    return state.personalAddress;
  },
  isHomeBased(state) {
    return state.isHomeBased;
  }
};

export const business = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
