import { render } from "./LfLoader.vue?vue&type=template&id=5d4dd4f0"
import script from "./LfLoader.vue?vue&type=script&lang=js"
export * from "./LfLoader.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5d4dd4f0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5d4dd4f0', script)) {
    api.reload('5d4dd4f0', script)
  }
  
  module.hot.accept("./LfLoader.vue?vue&type=template&id=5d4dd4f0", () => {
    api.rerender('5d4dd4f0', render)
  })

}

script.__file = "src/components/ui/LfLoader.vue"

export default script