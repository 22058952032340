import format from "date-fns/format";
import { SMARTY_STREETS_ENDPOINT } from "./constants";
import Axios from "axios";

export function formatMoney(value) {
  return `$${new Intl.NumberFormat().format(value)}`;
}

export function formatInputAmount(value) {
  return `${formatMoney(value.split(".")[0].replace(/\D/g, ""))}.00`;
}

export function getNumberFromAmount(value) {
  return value.replace(/,/g, "").replace("$", "");
}

export function getRangeOptionForNumber(number, options) {
  for (const [key, value] of Object.entries(options)) {
    if (key != Object.keys(options)[Object.keys(options).length - 1]) {
      const minAmount = parseInt(value.split("-")[0].replace(/\D/g, ""));
      const maxAmount = parseInt(value.split("-")[1].replace(/\D/g, ""));
      if (number >= minAmount && number <= maxAmount) return key;
    } else {
      return key;
    }
  }
}

export function formatDate(year, month, day, pattern = "yyyy-MM-dd") {
  return format(
    new Date(parseInt(year), parseInt(month), parseInt(day)),
    pattern
  );
}

export function moveToSecondInput() {
  //find input elements
  const allElements = document.querySelectorAll("input");
  allElements[1].focus();
}

//removes all the letters/symbols from a string and converts to int
export function cleanNumber(numberString) {
  return typeof numberString === "string"
    ? Number(numberString.replace(/[^0-9\\.]+/g, ""))
    : numberString;
}

export const smartyStreetsRequest = async params => {
  const key = process.env.VUE_APP_SMARTY_STREETS_KEY;
  return await Axios.get(SMARTY_STREETS_ENDPOINT, {
    params: {
      key,
      ...params
    }
  });
};
