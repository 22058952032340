import { render } from "./IconSuccess.vue?vue&type=template&id=4b52a156"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4b52a156"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4b52a156', script)) {
    api.reload('4b52a156', script)
  }
  
  module.hot.accept("./IconSuccess.vue?vue&type=template&id=4b52a156", () => {
    api.rerender('4b52a156', render)
  })

}

script.__file = "src/components/icons/IconSuccess.vue"

export default script