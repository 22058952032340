<template>
  <div>
    <button
      v-bind="$attrs"
      type="button"
      class="button-option w-full h-full rounded-lg border-gray-200 text-tiny-xs sm:text-sm text-gray-600 leading-6 font-semibold font-sans transition ease-in-out duration-150 sm:leading-5"
      :class="{ 'bg-gray-100 active': selected }"
      :style="{
        color: customization.label_font_colour,
        borderColor: selected ? customization.label_font_colour : '',
        '--color-hover': customization.label_font_colour
      }"
    >
      <slot></slot>
    </button>
  </div>
</template>
<script type="text/javascript">
export default {
  name: "lf-button-option",
  props: {
    selected: Boolean
  },
  setup() {
    const customization = {
      label_font_colour: "#fffffff"
    };

    return {
      customization
    };
  }
};
</script>

<style scoped>
.button-option:hover {
  color: var(--color-hover);
  border-color: var(--color-hover);
}
.button-option {
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.button-option:hover,
.button-option:focus,
.button-option.active {
  background: rgba(254, 74, 27, 0.1);
  color: rgb(254, 74, 27);
  border-color: rgb(254, 74, 27);
  box-shadow: none;
}
</style>
