<template>
  <div
    class="flex justify-center mx-2-5 sm:mx-5 px-2 sm:px-5 bg-gray-100 rounded place-items-center"
    :class="{ 'py-5': !showAdditionalReports }"
  >
    <radial-progress-bar
      :diameter="diameter"
      :completed-steps="completedStepsPercentage"
      :total-steps="100"
      :inner-stroke-color="'#e3eaf0'"
      :start-color="'#3769D6'"
      :stop-color="'#3769D6'"
      :is-clockwise="false"
      :stroke-width="strokeWidth"
      :inner-stroke-width="strokeWidth"
    >
      <span
        v-show="!showAdditionalReports"
        class="text-tiny-xs sm:text-sm text-gray-700 font-medium"
      >
        {{ completedStepsPercentage }}%</span
      >
    </radial-progress-bar>
    <div
      v-show="!showAdditionalReports"
      class="flex flex-col ml-2 sm:ml-5 text-left"
    >
      <span class="text-tiny-xs sm:text-base font-medium text-gray-700 mb-1-5"
        >Additional 2020 bank statements</span
      >
      <span class="text-xxs sm:text-xs text-gray-500 pr-5"
        >Submitting additional 2020 bank statements will increase your approval
        rates and terms</span
      >
    </div>
    <div
      v-show="showAdditionalReports"
      class="flex ml-2 sm:ml-5 text-tiny-xs sm:text-base text-left font-medium text-gray-700 w-full place-self-center"
    >
      Optional documents
    </div>
    <lf-round-button
      @click="toggleAdditionalReports"
      class="flex place-self-center"
    >
      <svg
        v-show="!showAdditionalReports"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="15" cy="15" r="15" fill="#E1EBFF" />
        <rect x="14" y="8" width="2" height="14" rx="1" fill="#3769D6" />
        <rect
          x="8"
          y="16"
          width="2"
          height="14"
          rx="1"
          transform="rotate(-90 8 16)"
          fill="#3769D6"
        />
      </svg>
      <svg
        v-show="showAdditionalReports"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="15" cy="15" r="15" fill="#E1EBFF" />
        <rect
          x="8.00122"
          y="16"
          width="2"
          height="14"
          rx="1"
          transform="rotate(-90 8.00122 16)"
          fill="#3769D6"
        />
      </svg>
    </lf-round-button>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import RoundButton from "./ui/RoundButton";
import RadialProgressBar from "vue-radial-progress";

export default {
  components: {
    "lf-round-button": RoundButton,
    RadialProgressBar
  },
  props: {
    diameter: Number,
    completedStepsPercentage: Number
  },
  setup(props, { emit }) {
    const showAdditionalReports = ref(false);

    function toggleAdditionalReports() {
      showAdditionalReports.value = !showAdditionalReports.value;
      emit("toggle-additional-docs-menu");
    }

    return {
      showAdditionalReports,
      toggleAdditionalReports,
      strokeWidth: computed(() => (showAdditionalReports.value ? 3 : 6))
    };
  }
};
</script>
