<template>
  <div class="flex flex-col">
    <div class="h-10 sm:h-13 flex relative">
      <input
        v-bind="$attrs"
        :value="modelValue"
        :type="inputType"
        class="lf-input text-xxs sm:text-sm bg-gray-200 rounded w-full h-full placeholder-gray-600"
        :class="[
          helpLabel || prefix ? 'pl-2-5 sm:pl-4' : 'text-center',
          { 'pt-2-5': (helpLabel && modelValue) || showHelpLabel },
          { 'border border-red-600 text-xs': !!errorMessage },
          { 'spinner-mr-15': suffix },
          { 'focus:outline-none': errorMessage }
        ]"
        :style="
          `color: ${customization.label_font_colour} !important; -webkit-text-fill-color: ${customization.label_font_colour};`
        "
        @input.stop="$emit('update:modelValue', $event.target.value)"
        :placeholder="placeholder"
        :maxlength="maxlength"
        :id="inputId"
        :autocomplete="autoCompleteDisabled"
        @focus="showPrefix = false"
        @blur="showPrefix = true"
        :max="max"
      />
      <label
        v-if="(helpLabel && modelValue) || showHelpLabel"
        :style="{ color: customization.label_font_colour }"
        for="input"
        class="absolute top-0 text-mob-xs sm:text-xxs leading-6"
        :class="[
          nomargin ? 'mt-0-5' : 'mt-1-5 sm:mt-2',
          nopadding ? 'pl-1' : 'pl-2-5 sm:pl-3-2'
        ]"
        >{{ helpLabel }}</label
      >
      <span
        v-if="prefix && !modelValue && showPrefix"
        class="absolute left-0 pt-2 ml-2-5 sm:ml-5 text-base sm:text-2xl"
        :style="{ color: customization.label_font_colour }"
      >
        {{ prefix }}
      </span>
      <span
        v-if="suffix"
        class="absolute right-0 mt-1 pt-1 sm:h-11 w-9 mr-2-5 sm:mr-3-2 text-base sm:text-2xl bg-gray-200"
        :style="{ color: customization.label_font_colour }"
      >
        {{ suffix }}
      </span>
      <span class="absolute right-0 pt-2-5 pr-2-5 sm:pt-3-3 sm:pr-3-3">
        <span
          v-if="controlInputVisibility && showInputContent"
          @click="toggleShowInputContent"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.8779 18.6996L1.30049 0.122022C1.13771 -0.0406741 0.87396 -0.0406741 0.711186 0.122022L0.122081 0.711166C-0.0406936 0.873863 -0.0406936 1.13769 0.122081 1.30043L3.56769 4.74607C1.92252 6.04796 0.678842 7.82273 0.0198927 9.87267C-0.00655278 9.95528 -0.00655278 10.0448 0.0198927 10.1274C1.43713 14.5374 5.44797 17.5001 10 17.5001C11.7805 17.5001 13.4926 17.049 15.0214 16.1998L18.6996 19.878C18.8623 20.0407 19.1261 20.0407 19.2889 19.878L19.878 19.2888C20.0407 19.1261 20.0407 18.8623 19.8779 18.6996ZM10 15.8334C6.78345 15.8334 4.16667 13.2166 4.16667 10C4.16667 8.73387 4.58304 7.5125 5.33305 6.5114L7.14458 8.32297C6.84474 8.82637 6.66669 9.39505 6.66669 10C6.66669 11.838 8.16205 13.3333 10 13.3333C10.605 13.3333 11.1737 13.1553 11.6771 12.8555L13.4886 14.667C12.4875 15.417 11.2662 15.8334 10 15.8334ZM9.6487 6.92504C9.58319 7.0809 9.61859 7.26157 9.73823 7.38118L12.6191 10.2621C12.6989 10.3418 12.8055 10.3841 12.9137 10.3841C12.9683 10.3841 13.0232 10.3735 13.0752 10.3516C13.2315 10.2856 13.3324 10.1322 13.3304 9.9626C13.3096 8.13681 11.8635 6.6907 10.0377 6.66992C9.87167 6.68781 9.7146 6.76879 9.6487 6.92504ZM7.49054 4.74083C7.33183 4.81654 7.14101 4.78478 7.01609 4.65947L6.20225 3.84563C6.10218 3.74555 6.05944 3.6011 6.08956 3.46274C6.11968 3.32399 6.21815 3.21047 6.35081 3.16082C7.52312 2.72215 8.75074 2.5 9.99993 2.5C14.552 2.5 18.5628 5.46267 19.9801 9.8727C20.0065 9.95532 20.0065 10.0448 19.9801 10.1274C19.4605 11.7441 18.5596 13.2309 17.3755 14.4268C17.2974 14.5053 17.1911 14.5497 17.0801 14.5501H17.0792C16.9685 14.5501 16.8627 14.5061 16.7846 14.428L15.3405 12.9839C15.2156 12.859 15.183 12.6689 15.2591 12.5094C15.64 11.7135 15.8333 10.8692 15.8333 10C15.8333 6.7835 13.2165 4.16669 9.99993 4.16669C9.13079 4.16669 8.28645 4.35997 7.49054 4.74083Z"
                fill="#7587A0"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
        <span
          v-else-if="controlInputVisibility && !showInputContent"
          @click="toggleShowInputContent"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 10C1.45897 5.63347 5.37928 2.5 10 2.5C14.6207 2.5 18.541 5.63347 20 10C18.541 14.3665 14.6207 17.5 10 17.5C5.37928 17.5 1.45897 14.3666 0 10ZM5 10C5 12.7614 7.23855 15 10 15C12.7614 15 15 12.7614 15 10C15 7.23857 12.7614 5.00002 10 5.00002C7.23855 5.00002 5 7.23857 5 10ZM11.7677 11.7677C12.744 10.7914 12.744 9.20853 11.7677 8.23223C10.7914 7.25592 9.20853 7.25592 8.23223 8.23223C7.25592 9.20853 7.25592 10.7914 8.23223 11.7677C9.20853 12.744 10.7914 12.744 11.7677 11.7677Z"
                fill="#7587A0"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
        <slot v-else name="icon" />
      </span>
    </div>
    <input-error :message="errorMessage" />
  </div>
</template>
<script type="text/javascript">
import { ref, computed } from "vue";
import InputError from "./InputError";
export default {
  name: "lf-input",
  components: {
    InputError
  },
  props: {
    type: String,
    modelValue: {
      type: [String, Number],
      default: ""
    },
    maxlength: {
      type: Number,
      default: 300
    },
    inputId: {
      type: [Number, String, null],
      default: null
    },
    autoCompleteDisabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    helpLabel: {
      type: String,
      default: ""
    },
    errorMessage: String,
    max: Number,
    min: Number,
    suffix: {
      type: String,
      default: null
    },
    controlInputVisibility: {
      type: Boolean,
      default: false
    },
    showHelpLabel: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      default: null
    },
    nomargin: {
      type: Boolean,
      default: false
    },
    nopadding: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const customization = {
      label_font_colour: "#fffffff"
    };
    const showInputContent = ref(false);
    const showPrefix = ref(true);
    const inputType = computed(() =>
      showInputContent.value ? "text" : props.type
    );

    function toggleShowInputContent() {
      showInputContent.value = !showInputContent.value;
    }

    return {
      inputType,
      showInputContent,
      toggleShowInputContent,
      showPrefix,
      customization
    };
  }
};
</script>

<style scoped>
.spinner-mr-15::-webkit-outer-spin-button,
.spinner-mr-15::-webkit-inner-spin-button {
  margin-right: 15px !important;
}

.es-field input {
  text-align: left !important;
  @apply pl-4;
}
</style>
