import { render } from "./RoundButton.vue?vue&type=template&id=108a5ed5"
import script from "./RoundButton.vue?vue&type=script&lang=js"
export * from "./RoundButton.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "108a5ed5"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('108a5ed5', script)) {
    api.reload('108a5ed5', script)
  }
  
  module.hot.accept("./RoundButton.vue?vue&type=template&id=108a5ed5", () => {
    api.rerender('108a5ed5', render)
  })

}

script.__file = "src/components/ui/RoundButton.vue"

export default script