<template>
  <div
    class="rounded-full h-11 w-11 flex items-center justify-center cursor-pointer"
  >
    <slot></slot>
  </div>
</template>
<script type="text/javascript">
export default {
  name: "lf-round-button"
};
</script>
