import { render } from "./bankstatements.vue?vue&type=template&id=2e68edc0"
import script from "./bankstatements.vue?vue&type=script&lang=js"
export * from "./bankstatements.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "2e68edc0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2e68edc0', script)) {
    api.reload('2e68edc0', script)
  }
  
  module.hot.accept("./bankstatements.vue?vue&type=template&id=2e68edc0", () => {
    api.rerender('2e68edc0', render)
  })

}

script.__file = "src/components/page/bankstatements.vue"

export default script