<template>
  <div class="body-wrapper body-wrapper--center">
    <div class="section">
      <div class="section__title section__title--center">
        Secure Bank Link
      </div>
      <div class="plaid">
        <div class="plaid__block">
          <div class="plaid__image">
            <img src="@/assets/images/plaid-logo.svg" alt="" />
          </div>
          <h3 class="plaid__title">
            Securely connect your bank account using Plaid
          </h3>
          <p class="plaid__text">
            Plaid has partnered with thousands of banks to provide you the most
            secure and convenient way to verify your bank account and deliver
            your bank statements.
          </p>
        </div>
      </div>
    </div>
    <div class="plaid">
      <div
        class="plaid-token"
        data-plaid-token="<%= session[:plaid_link_token] %>"
      ></div>
      <div class="plaid__cards">
        <div class="plaid__card">
          <img src="@/assets/images/icon1.svg" alt="" />
          <p>
            Bank level <br />
            securty
          </p>
        </div>
        <div class="plaid__card plaid__card--middle">
          <img src="@/assets/images/icon2.svg" alt="" />
          <p>Easier than <br />manual <br />statements</p>
        </div>
        <div class="plaid__card">
          <img src="@/assets/images/icon3.svg" alt="" />
          <p>
            Super fast<br />
            connection
          </p>
        </div>
      </div>
    </div>
    <div class="plaid__tip">
      You can securely conect your account in just a few clicks.
    </div>
    <button class="btn btn--submit btn--plaid" @click="connectUsingPlaid">
      Connect using Plaid
    </button>
    <button class="btn btn--no-border" @click="uploadStatementsManually">
      Upload statements manually
    </button>
  </div>
</template>

<script>
import { ref, computed, onBeforeUnmount } from "vue";
// import { onMounted } from "vue";
import { useStore } from "vuex";
import { loadScript, storePlaidToken } from "../plaid-loader.js";

export default {
  components: {},
  setup() {
    const store = useStore();
    const isLoading = ref(true);
    const plaidLink = computed(() => store.state.business.plaidLink);

    store.dispatch("setProgress", 80);

    const customization = {
      label_font_colour: "#fffffff",
      description_font_colour: "#000000"
    };

    async function connectUsingPlaid() {
      await loadScript();
      await storePlaidToken(plaidLink.value);
    }

    function uploadStatementsManually() {
      store.commit("setBankStatementScreen");
    }

    onBeforeUnmount(() => {
      if (window.linkHandler) {
        window.linkHandler.exit();
      }
    });

    return {
      customization,
      isLoading,
      subheading: "Securely connect your bank account using Plaid",
      description:
        "Plaid has partnered with thousands of banks to provide you the most secure and convenient way to verify your bank account and deliver your bank statements.",
      additionalDescription:
        "You can securely connect your account in just a few clicks.",
      buttonText: "Connect using Plaid",
      subButtonText: "Upload statements manually",
      footerText:
        "We take your privacy very seriously. We use bank level security to protect your personal and business information. Applying will not impact your credit score. We only use a soft pull to check your credit.",
      privacyText: "",
      connectUsingPlaid,
      uploadStatementsManually
    };
  }
};
</script>
