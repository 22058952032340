<template>
  <div
    :class="{
      'mt-5': loadType === 'docusign'
    }"
  >
    <ContentLoader
      width="500"
      :height="loadType === 'docusign' ? '500' : '630'"
      primaryColor="#E8E8E8"
      secondaryColor="#FFFFFF"
      :speed="0.7"
    >
      <!-- skeleton for docusign -->
      <template v-if="loadType === 'docusign'">
        <rect width="200" height="30" rx="4" fill="#EBEEF5" />
        <rect x="300" width="200" height="30" rx="4" fill="#EBEEF5" />
        <rect y="50" x="0" width="50" height="50" rx="4" fill="#EBEEF5" />
        <rect y="50" x="60" width="140" height="20" rx="4" fill="#EBEEF5" />
        <rect y="130" width="500" height="20" rx="4" fill="#EBEEF5" />
        <rect y="160" width="500" height="200" rx="4" fill="#EBEEF5" />
        <rect y="370" width="500" height="20" rx="4" fill="#EBEEF5" />
        <rect y="420" width="500" height="50" rx="4" fill="#EBEEF5" />
      </template>
      <!-- skeleton for credit score -->
      <template v-if="loadType === 'creditscore'">
        <rect y="50" width="220" height="50" rx="4" fill="#EBEEF5" />
        <rect x="280" y="50" width="220" height="50" rx="4" fill="#EBEEF5" />
        <rect y="130" width="500" height="50" rx="4" fill="#EBEEF5" />
        <rect y="210" width="500" height="50" rx="4" fill="#EBEEF5" />
        <rect y="290" width="500" height="50" rx="4" fill="#EBEEF5" />
        <rect y="370" width="500" height="50" rx="4" fill="#EBEEF5" />
        <rect y="530" width="500" height="50" rx="4" fill="#EBEEF5" />
      </template>
      <!-- default skeleton - funding widget -->
      <template v-else>
        <rect width="500" height="150" rx="4" fill="#EBEEF5" />
        <rect x="100" y="200" width="300" height="80" rx="4" fill="#EBEEF5" />
        <rect x="20" y="300" width="460" height="20" rx="4" fill="#EBEEF5" />
        <rect x="20" y="340" width="60" height="20" rx="4" fill="#EBEEF5" />
        <rect x="420" y="340" width="60" height="20" rx="4" fill="#EBEEF5" />

        <rect width="500" height="150" rx="4" fill="#EBEEF5" />
        <rect x="100" y="420" width="300" height="80" rx="4" fill="#EBEEF5" />
        <rect x="20" y="520" width="460" height="20" rx="4" fill="#EBEEF5" />
        <rect x="20" y="560" width="460" height="20" rx="4" fill="#EBEEF5" />
      </template>
    </ContentLoader>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";

export default {
  components: {
    ContentLoader
  },
  props: {
    loadType: {
      type: String,
      default: ""
    }
  }
};
</script>
