<template>
  <div>
    <lf-input
      @input="getSuggestions"
      :modelValue="modelValue"
      :placeholder="placeholder"
      :helpLabel="helpLabel"
      v-bind="$attrs"
      :nomargin="true"
    />
    <ul
      v-show="options && options.length"
      class="relative z-50 border border-gray-500 rounded"
    >
      <li
        v-for="(option, index) in options"
        :key="index"
        @click="select(index)"
        class="bg-white hover:bg-gray-200 text-left pl-2-5 text-gray-700"
      >
        <div class="flex">
          <svg
            class="w-6 h-6 pr-2-5 place-self-center"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="lightgray"
          >
            <path
              fill-rule="evenodd"
              d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
              clip-rule="evenodd"
            />
          </svg>
          {{ option.street }}, {{ option.city }}, {{ option.state }}
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import Input from "./Input";
import { ref, computed } from "vue";

export default {
  name: "auto-complete",
  components: {
    "lf-input": Input
  },
  props: {
    placeholder: String,
    helpLabel: String,
    modelValue: String,
    getAsyncOptions: Function,
    mapFunction: Function
  },
  setup(props, { emit }) {
    const suggestions = ref([]);
    const options = computed(
      () => suggestions.value && props.mapFunction(suggestions.value)
    );

    async function getSuggestions(event) {
      if (!event.target.value) {
        suggestions.value = [];
        return;
      }

      emit("update:modelValue", event.target.value);
      const data = await props.getAsyncOptions();
      suggestions.value = data;
    }

    function select(index) {
      emit("update:selectSuggestion", suggestions.value[index]);
      suggestions.value = [];
    }

    return {
      options,
      getSuggestions,
      select
    };
  }
};
</script>
