import WidgetApiService from "../../api-services/widget-api.service";

const state = {
  loanAmount: 10000,
  timeInBusiness: "time_in_business_2",
  monthlyAvgRevenueOptions: [],
  monthlyAvgRevenue: "monthly_avg_revenue_1",
  creditScore: "credit_score_1",
  email: "",
  fundingOffer: "",
  prequalificationId: "",
  widgetToken: "",
  selectedmonthlyAvgRevenueOption: null
};

const mutations = {
  setLoanAmount(state, loanAmount) {
    state.loanAmount = loanAmount;
  },
  setTimeInBusiness(state, timeInBusiness) {
    state.timeInBusiness = timeInBusiness;
  },
  setMonthlyAvgRevenueOptions(state, monthlyAvgRevenueOptions) {
    state.monthlyAvgRevenueOptions = monthlyAvgRevenueOptions;
  },
  setMonthlyAvgRevenue(state, monthlyAvgRevenue) {
    state.monthlyAvgRevenue = monthlyAvgRevenue;
    state.selectedmonthlyAvgRevenueOption = Object.keys(
      state.monthlyAvgRevenueOptions
    ).find(k => {
      const [, , , valToMatch] = k.split("_");
      return valToMatch === state.monthlyAvgRevenue;
    });
  },
  setCreditScore(state, creditScore) {
    state.creditScore = creditScore;
  },
  setEmail(state, email) {
    state.email = email;
  },
  setFundingOffer(state, fundingOffer) {
    state.fundingOffer = fundingOffer;
  },
  setPrequalificationId(state, prequalificationId) {
    state.prequalificationId = prequalificationId;
  },
  setWidgetToken(state, widgetToken) {
    state.widgetToken = widgetToken;
  }
};

const actions = {
  async getFundingOffer({ commit, state }) {
    const data = await WidgetApiService.getPreQualificationOffer({
      time_in_business: state.timeInBusiness,
      monthly_avg_revenue: state.selectedmonthlyAvgRevenueOption,
      credit_score: state.creditScore,
      requested_amount: state.loanAmount
    });
    commit("setPrequalificationId", data.prequalification_id);
    return commit("setFundingOffer", data);
  }
};

const getters = {};

export const values = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
