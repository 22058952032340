import { render } from "./plaid.vue?vue&type=template&id=46c3c850"
import script from "./plaid.vue?vue&type=script&lang=js"
export * from "./plaid.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "46c3c850"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('46c3c850', script)) {
    api.reload('46c3c850', script)
  }
  
  module.hot.accept("./plaid.vue?vue&type=template&id=46c3c850", () => {
    api.rerender('46c3c850', render)
  })

}

script.__file = "src/components/page/plaid.vue"

export default script