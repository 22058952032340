<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.1" cx="15" cy="15" r="15" fill="#BBBBC1" />
    <path
      d="M9 11H10.3333H21"
      stroke="#BBBBC1"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.333 10.9997V9.66634C12.333 9.31272 12.4735 8.97358 12.7235 8.72353C12.9736 8.47348 13.3127 8.33301 13.6663 8.33301H16.333C16.6866 8.33301 17.0258 8.47348 17.2758 8.72353C17.5259 8.97358 17.6663 9.31272 17.6663 9.66634V10.9997M19.6663 10.9997V20.333C19.6663 20.6866 19.5259 21.0258 19.2758 21.2758C19.0258 21.5259 18.6866 21.6663 18.333 21.6663H11.6663C11.3127 21.6663 10.9736 21.5259 10.7235 21.2758C10.4735 21.0258 10.333 20.6866 10.333 20.333V10.9997H19.6663Z"
      stroke="#BBBBC1"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
