import Axios from "axios";
import { format } from "util";

const ENDPOINTS = {
  METADATA: "/api/widget/metadata",
  PRE_QUALIFICATION_OFFER: "/api/widget/pre-qualification",
  RESUME_APP_CODE: "/api/widget/resume_code",
  RESUME_VERIFY_CODE: "/api/widget/resume_verify",
  BUSINESS_INFO: "/api/widget/applications",
  BUSINESS_ADDRESS: "/api/widget/applications/%s/business_address",
  PERSONAL_ADDRESS: "/api/widget/applications/%s/personal_address",
  EIN_SSN: "/api/widget/applications/%s/piis",
  FUND_USE: "/api/widget/applications/%s/fund",
  DOC_SIGNED: "/api/widget/applications/%s/is_signed",
  PLAID_DOCS: "/api/widget/applications/%s/plaid",
  OUTSTANDING_DEBT: "/api/widget/applications/%s/outstanding_dept",
  WEBSITE: "/api/widget/applications/%s/website",
  ASSETS: "/api/widget/applications/%s/assets",
  TAX_RETURN: "/api/widget/applications/%s/tax_return",
  PERSONAL_INCOME: "/api/widget/applications/%s/personal_income",
  ACCOUNTING_SOFTWARE: "/api/widget/applications/%s/accounting",
  WIDGET_FILES: "/api/widget/applications/%s/files",
  FINISH_APP: "/api/widget/applications/%s/finish",
  APPLICATION_METADATA: "/api/widget/applications/%s/metadata",
  WIDGET_LINKS: "/api/widget/applications/%s/links",
  BUSINESS_CREDIT_APPLICATION: "/api/widget/applications/business_credit",
  IS_BUSINESS_CREDIT_AVAILABLE: "/api/widget/business_credit_available",
  STORE_METADATA: "/api/widget/applications/%s/metadata"
};

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    Authorization: `Bearer ${process.env.VUE_APP_WIDGET_TOKEN}`
  }
});

export const setAuthToken = token => {
  axios.defaults.headers["Authorization"] = `Bearer ${token}`;
  axios.defaults.headers["x-referrer-origin"] =
    window.lendflowData?.referrer || "";
};

export const isBusinessCreditAvailable = async () => {
  const response = await axios.get(ENDPOINTS.IS_BUSINESS_CREDIT_AVAILABLE);
  return response.data.data;
};

export default {
  async getPreQualificationMetaData(data) {
    if (localStorage.widget) {
      return JSON.parse(localStorage.widget);
    }

    const response = await axios.get(ENDPOINTS.METADATA, {
      data
    });
    this.setWidgetCustomizationToLocalStorage(response.data.data);
    return response.data.data;
  },
  setWidgetCustomizationToLocalStorage(data) {
    localStorage.setItem("widget", JSON.stringify(data));
  },

  async getPreQualificationOffer(data) {
    const response = await axios.post(ENDPOINTS.PRE_QUALIFICATION_OFFER, data);
    return response.data.data;
  },

  async getResumeAppCode(email) {
    const response = await axios.post(ENDPOINTS.RESUME_APP_CODE, {
      email
    });
    return response.data.data;
  },

  async getLastCompletedStep(code) {
    const response = await axios.post(ENDPOINTS.RESUME_VERIFY_CODE, { code });
    return response.data.data;
  },

  async storeBusinessMetadata(data, applicationId) {
    await axios.post(format(ENDPOINTS.STORE_METADATA, applicationId), data);
  },

  async storeBusinessInfo(data) {
    const response = await axios.post(ENDPOINTS.BUSINESS_INFO, data);
    return response.data.data;
  },

  async storeBusinessAddress(data, applicationId) {
    const response = await axios.put(
      format(ENDPOINTS.BUSINESS_ADDRESS, applicationId),
      data
    );
    return response.data.data;
  },

  async storePersonalAddress(data, applicationId) {
    const response = await axios.put(
      format(ENDPOINTS.PERSONAL_ADDRESS, applicationId),
      data
    );
    return response.data.data;
  },

  async storeEinSSn(data, applicationId) {
    const response = await axios.put(
      format(ENDPOINTS.EIN_SSN, applicationId),
      data
    );
    return response.data.data;
  },

  async storeFundUse(data, applicationId) {
    const response = await axios.put(
      format(ENDPOINTS.FUND_USE, applicationId),
      data
    );
    return response.data.data;
  },

  async storeDocumentSigned(applicationId) {
    const response = await axios.get(
      format(ENDPOINTS.DOC_SIGNED, applicationId)
    );
    return response.data.data;
  },

  async storePlaidToken(data, applicationId) {
    const response = await axios.put(
      format(ENDPOINTS.PLAID_DOCS, applicationId),
      data
    );
    return response.data.data;
  },

  async uploadFile(applicationId, file, fileTypeName, additionalConfig = {}) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_type", fileTypeName);

    const response = await axios.post(
      format(ENDPOINTS.WIDGET_FILES, applicationId),
      formData,
      {
        headers: {
          "content-type": "multipart/form-data"
        },
        ...additionalConfig
      }
    );
    return response;
  },

  async storeOutstandingDebt(data, applicationId) {
    const response = await axios.put(
      format(ENDPOINTS.OUTSTANDING_DEBT, applicationId),
      data
    );
    return response.data.data;
  },

  async storeWebsite(data, applicationId) {
    const response = await axios.put(
      format(ENDPOINTS.WEBSITE, applicationId),
      data
    );
    return response.data.data;
  },

  async storeAssets(data, applicationId) {
    const response = await axios.put(
      format(ENDPOINTS.ASSETS, applicationId),
      data
    );
    return response.data.data;
  },

  async storeTaxReturn(data, applicationId) {
    const response = await axios.put(
      format(ENDPOINTS.TAX_RETURN, applicationId),
      data
    );
    return response.data.data;
  },

  async storePersonalIncome(data, applicationId) {
    const response = await axios.put(
      format(ENDPOINTS.PERSONAL_INCOME, applicationId),
      data
    );
    return response.data.data;
  },

  async storeAccountingSoftware(data, applicationId) {
    const response = await axios.put(
      format(ENDPOINTS.ACCOUNTING_SOFTWARE, applicationId),
      data
    );
    return response.data.data;
  },

  async getRecommendation(source) {
    const response = await axios.get(source);
    return response.data.suggestions;
  },

  async finishApp(data, applicationId) {
    const response = await axios.put(
      format(ENDPOINTS.FINISH_APP, applicationId),
      data
    );
    return response.data.data;
  },

  async getApplicationMetadata(applicationId) {
    const response = await axios.get(
      format(ENDPOINTS.APPLICATION_METADATA, applicationId)
    );
    return response.data.data;
  },

  async getWidgetLinks(applicationId, getDocusign, getPlaid) {
    const response = await axios.get(
      format(ENDPOINTS.WIDGET_LINKS, applicationId),
      {
        params: { docusign: getDocusign, plaid: getPlaid }
      }
    );
    return response.data.data;
  },

  async createBusinessCreditApp(data) {
    const response = await axios.post(
      ENDPOINTS.BUSINESS_CREDIT_APPLICATION,
      data
    );
    return response.data.data;
  }
};
