import { createStore } from "vuex";

import { business } from "./modules/business.module";
import { values } from "./modules/selected-values.module";
import { customizationMetadata } from "./modules/customization-metadata.module";

export default createStore({
  state: {
    progress: 0,
    loading: false,
    steps: [
      "procorePage",
      "preQualification",
      "business",
      "personal",
      "docuSign",
      "plaid",
      "done"
    ],
    active_step: "procorePage",
    active_step_index: 0,

    options: {
      time_in_business: "LFW_2",
      monthly_avg_revenue: "LFW_3",
      credit_score: "LFW_4",
      business_entity: "LFW_8",
      use_of_funds: "LFW_17",
      funds_needed_time: "LFW_18"
    }
  },
  getters: {
    getProgressPercent(state) {
      return state.progress;
    },
    getActiveScreen(state) {
      return state.active_step;
    },
    getLoading(state) {
      return state.loading;
    }
  },
  actions: {
    setNextScreen({ commit }) {
      commit("nextScreen");
    },
    setProgress({ commit }, progress) {
      commit("changeProgess", progress);
    },
    setLoading({ commit }, loading) {
      commit("setLoading", loading);
    }
  },
  mutations: {
    nextScreen(state) {
      if (state.active_step_index < state.steps.length - 1) {
        state.active_step_index++;
        state.active_step = state.steps[state.active_step_index];
      }
    },
    backScreen(state) {
      if (state.active_step_index > 0) {
        state.active_step_index--;
        state.active_step = state.steps[state.active_step_index];
      }
    },
    changeProgess(state, progress) {
      state.progress = progress;
    },
    setBankStatementScreen(state) {
      state.active_step = "bankstatements";
      state.active_step_index = 5;
    },
    setPlaidScreen(state) {
      state.active_step = "plaid";
      state.active_step_index = 5;
    },
    setLoading(state, loading) {
      state.loading = loading;
    }
  },
  modules: {
    business,
    values,
    customizationMetadata
  }
});
