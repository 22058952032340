import Axios from "axios";
import { cleanNumber } from "./helpers";

export const procore = {
  client_id: process.env.VUE_APP_PROCORE_CLIENT_ID,
  auth_url: process.env.VUE_APP_PROCORE_AUTH,
  resource_url: process.env.VUE_APP_PROCORE_RESOURCE,
  access_token: "",
  initilaze: function(callback) {
    this.callback = callback || this.default;
    this.context = window.ProcoreIframeHelpers.initialize();
    return this;
  },
  extractToken: function(hash) {
    var match = hash.match(/access_token=([\w.-]+)/);
    return !!match && match[1];
  },
  default: function(data) {
    console.log(data);
  },

  getAuth: function() {
    var authUrl =
      this.auth_url +
      "?response_type=token" +
      "&client_id=" +
      this.client_id +
      "&redirect_uri=" +
      window.location;

    this.context.authentication.authenticate({
      url: authUrl,
      onSuccess: function(data) {
        console.log(data);
        procore.callback({
          action: "ok",
          access_token: data.token
        });
      },
      onFailure: function(error) {
        procore.callback({ action: "error", message: error.reason });
      }
    });

    this.context.authentication.notifySuccess({
      token: this.extractToken(document.location.hash)
    });
  },
  getData: async function(token, company_id, project_id) {
    console.log(company_id);
    this.data = await Axios.get(
      this.resource_url + "/rest/v1.0/projects/" + project_id,
      {
        params: {
          company_id: company_id
        },
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json"
        }
      }
    );
  },

  getBusinessInfo() {
    if (typeof this.data != "undefined") {
      return {
        firstName: "",
        lastName: "",
        email: "",
        phone: cleanNumber(this.data.data.office?.phone) || "",
        legalName: this.data.data.office?.name || "",
        dba: this.data.data.office?.name || ""
      };
    }
  },
  getBusinessAddress() {
    if (typeof this.data != "undefined") {
      return {
        address: this.data.data.office?.address || "",
        secondAddress: "",
        country: this.data.data.office?.country_code || "",
        city: this.data.data.office?.city || "",
        state: this.data.data.office?.state_code || "",
        zip: this.data.data.office?.zip || ""
      };
    }
  }
};
