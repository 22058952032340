<template>
  <div class="relative" ref="target">
    <button
      type="button"
      @click="toggleMenu"
      aria-haspopup="listbox"
      aria-expanded="true"
      aria-labelledby="listbox-label"
      id="exp_button"
      class="relative w-full h-full bg-gray-200 text-gray-600 rounded shadow-sm -pl-1 sm:pl-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-gray-700 focus:border-gray-700 text-tiny-xs sm:text-sm"
      :style="
        `color: ${customization.label_font_colour} !important; -webkit-text-fill-color: ${customization.label_font_colour};`
      "
    >
      <span
        class="ml-3 truncate"
        :style="
          `color: ${customization.label_font_colour} !important; -webkit-text-fill-color: ${customization.label_font_colour};`
        "
      >
        {{ modelValue || placeholder }}
      </span>
      <span
        class="ml-3 absolute inset-y-0 right-0 flex items-center pr-1 cursor-pointer"
      >
        <svg
          class="h-5 w-5 -mr-1 sm:mr-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          :fill="customization.label_font_colour"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </span>
    </button>

    <div class="absolute mt-1 w-full rounded-md bg-white shadow-lg z-50">
      <ul
        tabindex="-1"
        role="listbox"
        aria-labelledby="listbox-label"
        aria-activedescendant="listbox-item-3"
        id="exp_elem_list"
        class="h-28 sm:h-40 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
        :class="{ hidden: !showMenu }"
        :style="
          `color: ${customization.label_font_colour} !important; -webkit-text-fill-color: ${customization.label_font_colour};`
        "
      >
        <li
          v-for="(option, idx) in options"
          :key="idx"
          class="pt-1 cursor-pointer hover:bg-gray-200 flex justify-between text-tiny-xs sm:text-base"
          id="listbox-item-0"
          role="option"
          :aria-selected="modelValue == option"
          @click="selectOption"
        >
          <div class="flex items-center">
            <span class="ml-3 font-normal truncate">
              {{ option }}
            </span>
          </div>

          <!-- Checkmark, only display for selected option. -->
          <span
            class="inset-y-0 flex items-center pr-2"
            v-show="modelValue == option"
          >
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";

export default {
  name: "lf-dropdown",
  props: {
    modelValue: [String, Number],
    options: Array,
    placeholder: String,
    closeOnOutsideClick: Boolean
  },
  setup(props, { emit }) {
    const showMenu = ref(false);
    const customization = {
      label_font_colour: "#fffffff"
    };

    const target = ref(null);
    onClickOutside(target, () => {
      showMenu.value = false;
    });

    function selectOption(event) {
      toggleMenu();
      emit("update:modelValue", event.target.innerText);
    }
    function toggleMenu() {
      showMenu.value = !showMenu.value;
    }

    return { showMenu, selectOption, toggleMenu, target, customization };
  }
};
</script>
