<template>
  <div class="row mt-5 mb-2-5">
    <span
      class="col-12 col-sm-6 col-md-4 col-lg-3"
      v-for="(value, index) in options"
      :key="index"
    >
      <lf-button-option
        class="h-10 sm:h-13 mb-3"
        @click.stop="$emit('selected', index)"
        :selected="isSelected(index)"
        :disabled="disableButtons"
      >
        {{ value }}
      </lf-button-option>
    </span>
  </div>
</template>
<script type="text/javascript">
import OptionButton from "../ui/ButtonOption";

export default {
  name: "lf-button-select",
  components: {
    "lf-button-option": OptionButton
  },
  props: {
    options: Object,
    selectedOption: [String, Array],
    multiselect: {
      type: Boolean,
      default: false
    },
    useFullWidth: {
      type: Boolean,
      default: false
    },
    disableButtons: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const numberOfOptions = Object.keys(props.options).length;

    function isSelected(value) {
      return props.multiselect
        ? props.selectedOption.includes(value)
        : props.selectedOption == value;
    }
    function isLastAndOddNumberedOption(value) {
      return (
        value.split("_").pop() == numberOfOptions && numberOfOptions % 2 == 1
      );
    }
    return { isSelected, isLastAndOddNumberedOption };
  }
};
</script>
