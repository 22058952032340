<template>
  <p
    v-show="message"
    class="pl-2 pt-3 text-xxs sm:text-xs text-red-700 text-left mb-0"
  >
    {{ message }}
  </p>
</template>

<script>
export default {
  props: {
    message: {
      required: true,
      type: String
    }
  }
};
</script>
