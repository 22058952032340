<template>
  <div class="body-wrapper body-wrapper--step">
    <div class="progress-block">
      <img src="@/assets/images/circle-green-2.svg" alt="" />
      <div class="progress-block__title">
        Great work! <br />Your application for funding has been submitted.
      </div>
      <div class="progress-block__subtitle progress-block__subtitle--sucsess">
        A funding advisor will reach out to you shortly.
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "done",
  setup() {
    const store = useStore();
    store.dispatch("setProgress", 100);
  }
};
</script>

<style scoped></style>
