import { defineRule, configure } from "vee-validate";
import { email, numeric } from "@vee-validate/rules";
import { getNumberFromAmount } from "./components/helpers.js";
import PhoneNumber from "awesome-phonenumber";

export const isValidUsPhone = phonenumber => {
  const phone = new PhoneNumber("+1" + phonenumber);
  //checks if regions is US
  const isUSnumber = phone.getRegionCode() === "US";
  //checks if format is valid
  const isValidPhone = phone.isValid();
  return isUSnumber && isValidPhone;
};

configure({
  validateOnBlur: true,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: false
});

defineRule("numeric", numeric);

defineRule("email", value => {
  return email(value) ? true : "Email address is invalid!";
});

defineRule("usPhone", value => {
  return isValidUsPhone(value) || "Not a valid US phone number";
});

defineRule("date", value => {
  return /^(0[1-9]|1[012])[-/](0[1-9]|[12][0-9]|3[01])[-/](19|20)\d\d$/.test(
    value
  )
    ? true
    : " This field must be a valid date in format MM/DD/YYYY!";
});

defineRule("required", value => {
  if (!value || !value.length) {
    return "This field is required!";
  }

  return true;
});

defineRule("name", value => {
  if (!value || !value.length) {
    return false;
  }

  if (/[^A-Za-z- ]+/.test(value)) {
    return "This field can only contain alpha characters, hyphens and spaces";
  }

  return true;
});

defineRule("countryCode", value => {
  return !value ||
    /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[0-9]|3[0-469]|2[70]|7|1)(?:\d*)$/.test(
      value
    )
    ? true
    : "This field must be a valid country code!";
});

defineRule("telephone", value => {
  return !value || /^(\d{6,14})$/.test(value)
    ? true
    : "This field must be a valid phone number!";
});

defineRule("percentage", value => {
  if (value < 0 || value > 100) {
    return "This field value must be between 0 and 100%";
  }

  return true;
});

defineRule("ein", value => {
  return !value ||
    /^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])-?\d{7}$/.test(
      value
    )
    ? true
    : " This field must be a valid EIN!";
});

defineRule("ssn", value => {
  return !value ||
    /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/.test(value)
    ? true
    : " This field must be a valid SSN!";
});

defineRule("maxAmount", value => {
  if (!value) return true;

  const amount = typeof value === "string" ? getNumberFromAmount(value) : value;
  return amount <= 1000000000
    ? true
    : "This must be less than $1,000,000,000.00!";
});

defineRule("url", value => {
  return !value ||
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(
      value
    )
    ? true
    : "This field must be a valid url!";
});

defineRule("year", value => {
  return !value || value < 1900 || value > new Date().getFullYear()
    ? "This field must be a valid year!"
    : true;
});

defineRule("hours", value => {
  return !value || value < 0
    ? "This field must be a valid amount of hours!"
    : true;
});
